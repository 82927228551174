import React,{useState, useEffect} from 'react';
import { AiOutlineShop } from "react-icons/ai";
import { FaRegUser } from "react-icons/fa";
import { BiHomeSmile } from "react-icons/bi";
import { GoHeart } from "react-icons/go";
import { MdOutlineShoppingBag } from "react-icons/md";
import {Link} from "react-router-dom";
import "./mobilesticky.css"

const MobileFooterMenuSticky = () => {
  
    const [isFooterStickyMenu, SetFooterStickyMenu] =useState();

    useEffect(()=>{
         const footerScroll = () =>{
            const scrollFooterTop = window.pageYOffset;
            SetFooterStickyMenu(scrollFooterTop > 350);
         }
         window.addEventListener('scroll', footerScroll);
        return () => {
          
            window.removeEventListener('scroll', footerScroll);

        };
    })

  return (
    <>
       <div className={`${isFooterStickyMenu ? "mobile-stickybar active" : ""} mobile-sticky-menu`}>
        <div className="mobile-stickybar-wrap">
            <ul className="mobile-sticky-wrp">
                <li className="home-bottom">
                    <Link to="/">
                        <BiHomeSmile />
                        <span>Home</span>
                    </Link>
                </li>
                <li>
                    <Link to="#">
                        <AiOutlineShop />
                        <span>Shop</span>
                    </Link>
                </li>
                <li>
                    <Link  to="/login">
                        <FaRegUser />
                        <span>Log in</span>
                    </Link>
                </li>

                <li>
                    <Link to="#" title="wishlist">
                        <GoHeart />
                        <span>Wishlist</span>
                    </Link>
                </li>

                <li className="cart-header">
                    <Link to="/cart">
                    <MdOutlineShoppingBag />
                        <span>cart</span>
                    </Link>
                </li>
            </ul>
        </div>
</div>

    </>
  )
}

export default MobileFooterMenuSticky
