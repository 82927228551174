import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import CustomButton from "../../../shared/CustomButton";
import SocialMediaSelect from "./SocialMediaSelect";

const AffiliateRegistrationForm = () => {

  return (
    <>
      <div className="hairclub-form">
        <h3 className="common-heading mb-4 text-center text-white">
        Affiliate Registration
        </h3>
        <Form className="form form-bg ">
          <Row className="mb-3">
            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                First Name <span className="text-danger">* </span>
              </Form.Label>
              <Form.Control type="tex" placeholder="Enter First Name" />
            </Form.Group>

            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                Last Name <span className="text-danger">* </span>
              </Form.Label>
              <Form.Control type="tex" placeholder="Enter Last Name" />
            </Form.Group>

            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                Date of Birth <span className="text-danger">* </span>
              </Form.Label>
              <Form.Control type="date" placeholder="Date of Birth" />
            </Form.Group>

            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                Gender <span className="text-danger">* </span>
              </Form.Label>
              <Form.Select>
                <option value="1">Male</option>
                <option value="2">Female</option>
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                Email <span className="text-danger">* </span>
              </Form.Label>
              <Form.Control type="email" placeholder="Enter Email " />
            </Form.Group>

            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                {" "}
                Password <span className="text-danger">* </span>
              </Form.Label>
              <Form.Control type="password" placeholder="Password" />
            </Form.Group>

            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                {" "}
                Confirm Password  <span className="text-danger">* </span>
              </Form.Label>
              <Form.Control type="password" placeholder="Confirm Password" />
            </Form.Group>

            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                Mobile <span className="text-danger">* </span>
              </Form.Label>
              <Form.Control type="text" placeholder="Enter Mobile No." />
            </Form.Group>

            <Form.Group as={Col} md="12" controlId="formFile" className="mb-3">
              <Form.Label>Id Proof</Form.Label>
              <Form.Control type="file" />
            </Form.Group>


            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                Address <span className="text-danger">* </span>
              </Form.Label>
              <Form.Control type="text" placeholder="Enter Your Address " />
            </Form.Group>

            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
              City <span className="text-danger">* </span>
              </Form.Label>
              <Form.Control type="text" placeholder="Enter City" />
            </Form.Group>

            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                State <span className="text-danger">* </span>
              </Form.Label>
              <Form.Control type="text" placeholder="Enter State" />
            </Form.Group>

            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
              Postal Code <span className="text-danger">* </span>
              </Form.Label>
              <Form.Control type="text" placeholder="Enter Postal Code" />
            </Form.Group>

            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                Country <span className="text-danger">* </span>
              </Form.Label>
              <Form.Select as={Col} md="6" className="mb-3">
                <option value="">Select Country</option>
                <option value="Afghanistan">Afghanistan</option>
                <option value="Åland Islands">Åland Islands</option>
                <option value="Albania">Albania</option>
                <option value="Algeria">Algeria</option>
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
               Website Address <span className="text-danger">* </span>
              </Form.Label>
              <Form.Control type="email" placeholder="Website Address" />
            </Form.Group>
           
            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
              Why do you want to become an affiliate?
                <span className="text-danger">* </span>
              </Form.Label>
              <Form.Control type="text" placeholder="Why do you want to become an affiliate?" />
            </Form.Group>

            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
              Why do you want to become an affiliate?
                <span className="text-danger">* </span>
              </Form.Label>
              <Form.Control type="text" placeholder="Why do you want to become an affiliate?" />
            </Form.Group>

            <Form.Group as={Col} md="12" className="mb-3">
              <SocialMediaSelect/>
            </Form.Group>

            <Col xs="12">
              <CustomButton
                outline={true}
                outlineColor="#3C3436"
                rounded={true} 
                color="#fff0"
                text="REGISTER"
                textColor="#fff"
                hoverTextColor="#3C3436"
                backgroundColor="#C75E0A"
                hoverBackgroundColor="#fff"
              />
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default AffiliateRegistrationForm;
