import React from 'react';
import Store from './component/Store';
import "./store_at.css"
import StoreImg from "../../assets/store/store-1.jpg";

const StoreAt = () => {
    const storeInfoList = [
        {
          name: "Robertson's",
          address: "56 Reid Street, Hamilton, Bermuda",
          phone: "+1 441-621-5272",
          email: "luxurycutsbermuda@gmail.com",
          storeHours: [
            { day: "Mon-Fri ", openTime: "9:00am", closeTime: "7:00pm" },
            { day: "Sat ", openTime: "9:00am", closeTime: "7:00pm" },
            { day: "Sun ", openTime: "2:00pm", closeTime: "6:00pm" }
          ],
          imageUrl: StoreImg,
         
        },
        {
          name: "Another Store",
          address: "123 Main Street, City, Country",
          phone: "+123 456-7890",
          email: "anotherstore@example.com",
          storeHours: [
            { day: "Mon-Fri", openTime: "8:00am", closeTime: "6:00pm" },
            { day: "Sat", openTime: "9:00am", closeTime: "5:00pm" },
            { day: "Sun", openTime: "Closed", closeTime: "Closed" }
          ],
          imageUrl: StoreImg,
         
        },

        {
            name: "Another Store",
            address: "123 Main Street, City, Country",
            phone: "+123 456-7890",
            email: "anotherstore@example.com",
            storeHours: [
              { day: "Mon-Fri", openTime: "8:00am", closeTime: "6:00pm" },
              { day: "Sat", openTime: "9:00am", closeTime: "5:00pm" },
              { day: "Sun", openTime: "Closed", closeTime: "Closed" }
            ],
            imageUrl: StoreImg,
           
          },

          {
            name: "Another Store",
            address: "123 Main Street, City, Country",
            phone: "+123 456-7890",
            email: "anotherstore@example.com",
            storeHours: [
              { day: "Mon-Fri", openTime: "8:00am", closeTime: "6:00pm" },
              { day: "Sat", openTime: "9:00am", closeTime: "5:00pm" },
              { day: "Sun", openTime: "Closed", closeTime: "Closed" }
            ],
            imageUrl: StoreImg,
           
          },

          {
            name: "Another Store",
            address: "123 Main Street, City, Country",
            phone: "+123 456-7890",
            email: "anotherstore@example.com",
            storeHours: [
              { day: "Mon-Fri", openTime: "8:00am", closeTime: "6:00pm" },
              { day: "Sat", openTime: "9:00am", closeTime: "5:00pm" },
              { day: "Sun", openTime: "Closed", closeTime: "Closed" }
            ],
            imageUrl: StoreImg,
           
          },

          {
            name: "Another Store",
            address: "123 Main Street, City, Country",
            phone: "+123 456-7890",
            email: "anotherstore@example.com",
            storeHours: [
              { day: "Mon-Fri", openTime: "8:00am", closeTime: "6:00pm" },
              { day: "Sat", openTime: "9:00am", closeTime: "5:00pm" },
              { day: "Sun", openTime: "Closed", closeTime: "Closed" }
            ],
            imageUrl: StoreImg,
           
          },

          {
            name: "Another Store",
            address: "123 Main Street, City, Country",
            phone: "+123 456-7890",
            email: "anotherstore@example.com",
            storeHours: [
              { day: "Mon-Fri", openTime: "8:00am", closeTime: "6:00pm" },
              { day: "Sat", openTime: "9:00am", closeTime: "5:00pm" },
              { day: "Sun", openTime: "Closed", closeTime: "Closed" }
            ],
            imageUrl: StoreImg,
           
          },

          {
            name: "Another Store",
            address: "123 Main Street, City, Country",
            phone: "+123 456-7890",
            email: "anotherstore@example.com",
            storeHours: [
              { day: "Mon-Fri", openTime: "8:00am", closeTime: "6:00pm" },
              { day: "Sat", openTime: "9:00am", closeTime: "5:00pm" },
              { day: "Sun", openTime: "Closed", closeTime: "Closed" }
            ],
            imageUrl: StoreImg,
           
          },
        
      ];
  return (
    <>
      <section className='ptop-5 pbottom-5'>
         <div className='side-space'>
            <div className='store-list'>
              {storeInfoList.map((storeInfo, index) => (
                    <Store key={index} storeInfo={storeInfo} />
                ))}
            </div>
         </div>
      </section>
    </>
  )
}

export default StoreAt
