import React from 'react';

import { Col, Image, Row } from 'react-bootstrap';
import FooterLogo from "../../assets/footer_logo.png";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { Link } from 'react-router-dom';
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdOutlineAlternateEmail } from "react-icons/md";
import BusIcon from "../../../src/assets/bus.png";
import { FaInstagram , FaFacebookF , FaYoutube } from "react-icons/fa";
import PaymentImg from "../../assets/payment.png"
import "./footer.css";
import ScrollToTop from '../../shared/ScrollToTop';
import MobileFooterMenuSticky from './MobileFooterMenuSticky';

const Footer = () => {
  return (
    <>
      
       <section className='footer'>
         <div className='side-space '> 
             <Row>
                <Col xl="5" lg="5" md="12" xs="12">
                      <div className='d-block d-sm-flex gap-sm-4 footer-about pb-3 pb-md-5 pt-5'>
                           <div className='pe-3'>
                            <Image src={FooterLogo} alt="logo" className='img-fluid footer-logo'/>
                            </div>
                            <div>
                                <h3>What's new at <br></br>
                            www.drbrownslab.com</h3>
                            <p className='mt-3 mt-lg-5 mb-4' style={{fontSize:'18px'}}>Get the latest news about artificial intelligence and green buildings.</p>

                            <div className='mt-3 mb-2'>
                            <InputGroup className="mb-4">
                                <Form.Control
                                placeholder="Email"
                                aria-label="Email"
                                aria-describedby="basic-addon2"
                                className='h-57'
                                />
                                <InputGroup.Text id="basic-addon2"><HiOutlineArrowNarrowRight  className='arrowIcon'/>
</InputGroup.Text>
                            </InputGroup>
                            </div>

                            <p style={{fontSize:'13px'}}>You can unsubscribe from these communications at any time. For more information on how to unsubscribe, our privacy practices, and how we
                                are committed to protecting and respecting your privacy, please review our Privacy Policy.</p>

                            <p className='pt-2 mb-0 mb-md-2' style={{fontSize:'18px', fontWeight:'300'}}>©Dr. Browns Labroatory, 2024</p>
                    </div>
                  </div>
                </Col>
                <Col xl="7" lg="7"  md="12" xs="12">
                   <div className='ps-0 ps-xl-4 pb-3 pb-mt-5 pt-3 pt-md-5'>
                   <Row className=''>
                        <Col xl="4" md="4" sm="6" xs="12">
                          <div className='footer-link'>
                             <h3>Categories</h3>
                             <ul>
                                <li><Link to="/shop" onClick={ScrollToTop}>Hair Growth</Link></li>
                                <li><Link to="/shop" onClick={ScrollToTop}>Skin Care</Link></li>
                                <li><Link to="/shop" onClick={ScrollToTop}>Health & Wellbeing</Link></li>
                                <li><Link to="/about-us" onClick={ScrollToTop}>About Us</Link></li>
                                <li><Link to="/faq" onClick={ScrollToTop}>FAQ's</Link></li>
                                <li><Link to="/blog" onClick={ScrollToTop}>Blog</Link></li>
                             </ul>
                          </div>
                        </Col>
                        <Col xl="4" md="4" sm="6" xs="12">
                        <div className='footer-link'>
                             <h3>Head Quaters</h3>
                              <ul>
                                  <li><Link to="#">7 North Shore Road, <br/>
                                      Devonshire <br/>
                                      Dv01, Bermuda, <br/>
                                      Next to Empire Grocery</Link></li>
                                  
                              </ul>
                          </div>
                        </Col>
                        <Col xl="4" md="4" sm="6" xs="12">
                        <div className='footer-link mt-3 mt-md-0'>
                             <h3>Mailing Address</h3>
                             <ul>
                                <li><Link to="#" onClick={ScrollToTop}>Dr Brown's Laboratories Ltd, <br></br>P.O. Box HM1839, Hamilton HMGX Bermuda</Link></li>
                                <li><Link to="tel:+1(441)293-5476" onClick={ScrollToTop}><BsFillTelephoneFill  className='phoneIcon me-2' />
 +1(441)293-5476</Link></li>
                                <li><Link to="mailto:info@drbrownslab.com" onClick={ScrollToTop}><MdOutlineAlternateEmail  className='me-2' />
  info@drbrownslab.com</Link></li>
                                
                             </ul>
                          </div>
                        </Col>
                    </Row>

                    <Row className='mt-4'>
                        <Col  xl="12" lg="12"  md="12" xs>
                           <div className='gap-3 footer-strip justify-content-between align-items-center'>
                              <div className='d-flex align-items-center gap-3 gap-md-4'>
                                  <div>
                                       <Image src={BusIcon} alt="icon"/>
                                  </div>

                                  <div>
                                       <h3>FREE SHIPPING</h3>
                                       <p className='mb-0 mt-1'>Free delivery now on your first order and over $200 <br/>
We have Bermuda & USA warehouse, Plus we ship globally!</p>
                                  </div>
                              </div>

                              <div className='border-left-white-1 d-none d-md-block'>
                                <h3 className='ps-3 py-4 text-start text-md-center'>only $250</h3>
                              </div>
                           </div>
                        </Col>
                    </Row>

                    <hr className='my-4' style={{borderColor:'#494949'}}></hr>

                    <Row className='align-items-center'>
                        {/* <Col xl="3" md="6" xs="12">
                          <div className='footer-second-list'>
                           
                             <ul>
                                <li><Link to="#">Terms of service</Link></li>
                                <li><Link to="#">Privacy Policy</Link></li>
                                <li><Link to="#">Refund Policy</Link></li>
                                <li><Link to="#">Subscription Policy </Link></li>
                                
                             </ul>
                          </div>
                        </Col> */}
                        <Col  xl="8" md="6" xs="12">
                           <div className='payment-strip'>
                              <p>Terms of services, Privacy Policy, Refund Policy, Subscription Policy</p>
                              <Image src={PaymentImg} alt="pyment" className='img-fluid'/>
                           </div>
                        </Col>
                        <Col xl="4" md="6" xs="12">
                        <div className='footer-second-list'>
                             <ul  className='d-flex gap-3 align-items-center justify-content-start justify-content-md-end mt-3 mt-md-0'>
                                <li><Link to="https://www.facebook.com/drbrownslaboratory" target="_blank" ><FaFacebookF  style={{fontSize:'18px'}}/></Link></li>
                                <li><Link to="https://www.instagram.com/drbrownslaboratory/" target="_blank"><FaInstagram style={{fontSize:'18px'}}/></Link></li>
                                <li><Link to="https://www.youtube.com/channel/UCrVOWQ49v-q-iBcaoNT-4pg" target="_blank"><FaYoutube style={{fontSize:'18px'}}/> </Link></li>
                                
                             </ul>
                          </div>
                        </Col>
                    </Row>
                   </div>
                </Col>
             </Row>
             
             {/* footer sticky menu */}
             <MobileFooterMenuSticky/>

         </div> 
       </section>
    </>
  )
}

export default Footer;
