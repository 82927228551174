import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import CustomButton from "../../shared/CustomButton";
import { FaRegTrashAlt } from "react-icons/fa";
import "./testimonial.css";
import TestimonialCard from "./TestimonialCard";
import testGallery from "../../assets/gal-2.jpg" 
import { Swiper, SwiperSlide  } from 'swiper/react';
import { Pagination , Grid} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/grid';

const TestimonialForm = () => {
  const [fileInputs, setFileInputs] = useState([{ id: 1 }]);

  const handleAddFileInput = () => {
    const newId = fileInputs[fileInputs.length - 1].id + 1;
    setFileInputs([...fileInputs, { id: newId }]);
  };

  const handleRemoveFileInput = (id) => {
    setFileInputs(fileInputs.filter((input) => input.id !== id));
  };

  const testimonialData = [
    {
      id: 1,
      imageUrl: testGallery,
      rating: 5,
      date: "Oct 30, 2023",
      description: "La dr.ssa Fabiani è molto preparata e attenta alle varie problematiche. Ascolta accuratamente il paziente e per me, ormai da 20 anni, ha sempre trovato la giusta cura. La consiglio vivamente!",
      author: "Wilmer Stevenson"
    },

    {
      id: 2,
     imageUrl: testGallery,
      rating: 5,
      date: "Oct 30, 2023",
      description: "La dr.ssa Fabiani è molto preparata e attenta alle varie problematiche. Ascolta accuratamente il paziente e per me, ormai da 20 anni, ha sempre trovato la giusta cura. La consiglio vivamente!",
      author: "Wilmer Stevenson"
    },

    {
      id: 3,
      imageUrl: testGallery,
      rating: 5,
      date: "Oct 30, 2023",
      description: "La dr.ssa Fabiani è molto preparata e attenta alle varie problematiche. Ascolta accuratamente il paziente e per me, ormai da 20 anni, ha sempre trovato la giusta cura. La consiglio vivamente!",
      author: "Wilmer Stevenson"
    },

    {
      id: 4,
     imageUrl: testGallery,
      rating: 5,
      date: "Oct 30, 2023",
      description: "La dr.ssa Fabiani è molto preparata e attenta alle varie problematiche. Ascolta accuratamente il paziente e per me, ormai da 20 anni, ha sempre trovato la giusta cura. La consiglio vivamente!",
      author: "Wilmer Stevenson"
    },
    {
      id: 5,
     imageUrl: testGallery,
      rating: 5,
      date: "Oct 30, 2023",
      description: "La dr.ssa Fabiani è molto preparata e attenta alle varie problematiche. Ascolta accuratamente il paziente e per me, ormai da 20 anni, ha sempre trovato la giusta cura. La consiglio vivamente!",
      author: "Wilmer Stevenson"
    },
    {
      id: 6,
     imageUrl: testGallery,
      rating: 5,
      date: "Oct 30, 2023",
      description: "La dr.ssa Fabiani è molto preparata e attenta alle varie problematiche. Ascolta accuratamente il paziente e per me, ormai da 20 anni, ha sempre trovato la giusta cura. La consiglio vivamente!",
      author: "Wilmer Stevenson"
    },
 
   
  ];

  return (
    <section className=" ptop-5 pbottom-5">
      <div className="side-space">
        <h2 className="heading-h2 text-center mb-5">
          Please tell us about your experience <br></br> with our product
        </h2>
        <Row>
          <Col xl="6" lg="7" xs="12">
             <div className="">
             <Swiper
              pagination={{
                clickable: true,
              }}
               modules={[Grid, Pagination]}
              spaceBetween={10}
              slidesPerView={1}
              grid={{ rows: 2, fill: 'row' }}
              breakpoints={{

                0: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                  grid: { rows: 1, fill: 'row' }
                },

                580: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                  grid: { rows: 2, fill: 'row' }
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                  grid: { rows: 2, fill: 'row' }
                }
              }}
            >
              {testimonialData.map(testimonial => (
                <SwiperSlide key={testimonial.id}>
                  <TestimonialCard testimonial={testimonial} />
                </SwiperSlide>
              ))}
            </Swiper>
             </div>

          </Col>
        
          <Col xl="6" lg="5" xs="12">
            <div className="hairclub-form testi-lable m-auto mt-3 mt-lg-0">
              <Row cloassName="">
                <Col xl="12" xs="12" className="m-auto">
                  <Form>
                    <Form.Group controlId="formName" className="mb-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control type="text" placeholder="Enter your name" />
                    </Form.Group>
                    <Form.Group controlId="formTitle" className="mb-3">
                      <Form.Label>Title</Form.Label>
                      <Form.Control type="text" placeholder="Enter title" />
                    </Form.Group>
                    <Form.Group controlId="formDescription" className="mb-3">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Enter description"
                      />
                    </Form.Group>

                    {fileInputs.map((input, index) => (
                      <Form.Group
                        controlId={`formFile${index}`}
                        key={input.id}
                        className="mb-3"
                      >
                        <Form.Label>Upload Image</Form.Label>
                        <div className="d-flex">
                          <Form.Control type="file" />
                          {index !== 0 && (
                            <button
                              type="button"
                              className="btn btn-danger ms-2"
                              onClick={() => handleRemoveFileInput(input.id)}
                            >
                              <FaRegTrashAlt />
                            </button>
                          )}
                        </div>

                        {index === fileInputs.length - 1 && (
                          <button
                            type="button"
                            className="btn btn-gray mt-3"
                            onClick={handleAddFileInput}
                          >
                            Add More
                          </button>
                        )}
                      </Form.Group>
                    ))}

                    <div>
                      <CustomButton
                        border={false}
                        borderWidth={0}
                        rounded={true}
                        color="#ffd814"
                        text="Submit"
                        textColor="#fff"
                        hoverTextColor="#fff"
                        backgroundColor="#C75E0A"
                        hoverBackgroundColor="#C75E0A"
                      />
                    </div>
                  </Form>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default TestimonialForm;
