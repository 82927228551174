import React from 'react'
import { Col, Image, Row } from 'react-bootstrap'
import VideoSection from './component/VideoSection';
import "./about_us.css";
import AboutImage from "../../assets/dr-brown.png"
import VideoCard from '../Home/video/component/VideoCard';
import ThumbnailImg from "../../assets/about-video-banner.jpg";


const AboutUs = () => {

const contentData = [
    {
        type: "video",
        thumbnailUrl: ThumbnailImg,
        videoUrl: "https://www.youtube.com/embed/L3HqwpSqnSY?si=jFafQDOtskQjf4Jd",
    },
];
    

  return (
    <>
        <secion>
            <VideoSection/>
        </secion>
    
        <section className='side-space ptop-5 pbottom-5'>
            <Row className='justify-content-center'>
                <Col xl="9" lg="12" xs="12">
                   <div class="about-info ">
                        <h2 class="mb-2 heading-h1 text-start text-sm-center">MEET OUR AWARD-WINNING OWNER &amp; FORMULATOR</h2>
                        <h4 class="mb-4 common-heading text-start text-sm-center">About Dr. Kyjuan H Brown, JP, MD, MH, DipDerm, CCFP</h4>

                        <p class="">
                            Dr. Brown received his Medical Doctorate degree from the prestigious St. Georges University School of Medicine, and his degree in Dermatology from prestigious Welsh Institute of Dermatology at the University of Cardiff, Wales, UK.
                        </p>

                        <p>
                            In 2015 Dr. Brown was the recipient of the acclaimed Professor Hywel Williams Research Prize for the most outstanding research paper with showed the greatest relevance to Dermatology and Medical Hair Restoration.
                        </p>
                        <p>Dr. Brown has many certifications and specialties. He is a Certified Hair Coach of Bauman Medical Training Group. He is also a Master Herbalist and also certified in Medical Weight Loss.</p>

                        <p>
                            Dr. Kyjuan Brown successfully completed the Bauman Certified HairCoachMD TM Training Program, which educates medical professionals on how to detect and monitor male and female pattern hair loss, perform hair re-growth treatments, as
                            well as provide patients with actionable information and effective preventative treatment options
                        </p>

                        <p>
                            Dr. Brown has written numerous articles, given presentations and has appeared on television talking about medical health &amp; nutrition, medical hair restoration and skin care. His product line is specifically formulated by him
                            based on his scientific research and experience as the Medical Director and practicing Physician of NorthShore Medical &amp; Aesthetics Center, which also includes a successful dermatology and hair restoration center.
                        </p>
                    </div>

                    <div className='my-3'>
                        <Image src={AboutImage} alt="about us" fluid />
                    </div>

                   <div className='pt-3 about-info'>
                        <h2 class="heading-h1 text-start text-sm-center mb-3">About The Brand</h2>
                        <p>
                            Dr. Brown’s Laboratory is a doctor-formulated brand crafted by award-winning physician and hair restoration specialist Kyjuan H. Brown, M.D. We have everything for you from essential health vitamins and supplements to hair growth
                            and restoration products, and skincare treatments. Our health and nutrition supplements are designed to support optimal health and are non-GMO, vegan, and gluten-free.
                        </p>

                        <p>
                            The product line also consists of hair growth and restoration products geared towards ensuring men and women of all ages can have the hair they desire. Our complete haircare product line has products that will grow your hair from
                            over the counter products like our edge control, shampoo, and hair growth serum to prescription products. These products do everything from increasing hair growth, thickening hair, reducing hair shedding and dandruff, moisturizing
                            &amp; Hydrating your hair.
                        </p>
                        <p>
                            Our hair restoration products are supported by our Salon B Hair Restoration Clinic located at NorthShore Medical Center. Our brand is not complete without skincare products formulated to help with various things like reducing
                            wrinkles, pigmentation and acne, hydrating the skin, and accelerating collagen production.
                        </p>
                    </div>
                    
                </Col>
            </Row>
        </section>

        <section>
           <VideoCard contents={contentData} />
        </section>
    </>
  )
}

export default AboutUs
