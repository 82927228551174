import React, { useState } from 'react';
import PropTypes from 'prop-types';
import "./link_custom.css";

const CustomButton = ({ border, borderWidth, rounded, color, borderColor, textColor, text, backgroundColor, hoverBackgroundColor, hoverTextColor }) => {

  const [hovered, setHovered] = useState(false);
  const [textHovered, setTextHovered] = useState(false);

  const classes = `btn-style  w-100 ${border ? 'btn-border-' + borderColor : 'btn-'}${color}`;

  const style = {
    backgroundColor: hovered ? hoverBackgroundColor : backgroundColor,
    color: textHovered ? hoverTextColor : textColor,
    border: border ? `${borderWidth}px solid ${borderColor}` : 'none',
    borderRadius: rounded ? '5px' : '0' 
  };

  return (
    <button 
      type="submit"
      className={classes} 
      style={style} 
      onMouseEnter={() => {
        setHovered(true);
        setTextHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
        setTextHovered(false);
      }}
    >
      {text}
    </button>
  );
};

CustomButton.propTypes = {
  border: PropTypes.bool,
  borderWidth: PropTypes.number,
  rounded: PropTypes.bool, 
  color: PropTypes.string.isRequired,
  borderColor: PropTypes.string,
  textColor: PropTypes.string,
  text: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  hoverBackgroundColor: PropTypes.string,
  hoverTextColor: PropTypes.string
};

CustomButton.defaultProps = {
  border: false,
  borderWidth: 1,
  rounded: false, 
  borderColor: '#CFD8DC',
  textColor: '#000000',
  hoverTextColor: '#FFFFFF'
};

export default CustomButton;
