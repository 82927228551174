
import React from 'react';

import { MdOutlineShoppingBag } from "react-icons/md";

import Image from 'react-bootstrap/Image';
import "./prod_card.css";
import { IoHeart } from "react-icons/io5";
import { FaStar } from "react-icons/fa";
import { Link } from 'react-router-dom';
import ScrollToTop from "./ScrollToTop";
import CartPopup from "./CartPopup";


const ProductCard = ({ product }) => {
  const { title, price, oldPrice, imageSrc, rating , inStock , hairClubMember } = product;
  

  return (
    <div className='prod-card'>
      <div className='prod-card-img'>
        <Image src={imageSrc} fluid className='prod-img'/>
        <div className='product-info'>
          <div className='out-of-stock-box'>
            {inStock ? (
            <span className='out-of-stock bg-success'>Stock In</span>
          ) : (
            <span className='out-of-stock'>Out of Stock</span>
          )}
            <div className='wishlist'> <IoHeart stroke="black" strokeWidth={25} /></div>
          </div>
        </div>
      </div>
      <div className='prod-details'>
        
        <Link to="/product-details" onClick={ScrollToTop} className='prod-title mb-2'>{title}</Link>
        <p className='prod-price mb-0 mt-1 mt-sm-0'>
          US ${price} <span className='price-light line-through ms-3'> ${oldPrice} </span>
        </p>
       
        <div className='d-flex justify-content-between align-items-center mt-1'>
          <span className="rating-star">
            {rating} <FaStar className='star-icon me-2'/> | 0
          </span>

          {/* <Link to="/cart" onClick={ScrollToTop}  className='prod_cart'><MdOutlineShoppingBag /> </Link> */}
          <CartPopup/>
        </div>
        <p className='member-text mt-1 text-start mb-0 ' >{hairClubMember}</p>
      </div>
    </div>
  );
};

export default ProductCard;
