import React from 'react'

import { Row , Col } from 'react-bootstrap'
import { Swiper, SwiperSlide  } from 'swiper/react';
import { Pagination} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

import CategoryCard from './component/CategoryCard'
import VideoCard from '../video/component/VideoCard';
import ThumbnailImg from "../../../assets/video/thumnialimg-1.jpg";
import CategorImg from "../../../assets/category/cate-1.jpg";
import CategorImg1 from "../../../assets/category/cate-2.jpg"
import CategorImg2 from "../../../assets/category/cate-3.jpg"

// Install Swiper components

const Category = () => {

const contentData = [
    {
      type: "video",
      thumbnailUrl: ThumbnailImg,
      videoUrl: "https://www.youtube.com/embed/video1",
    },
];

const breakpoints = {
  1300: {
    slidesPerView: 3,
    spaceBetween: 0,
  },
  1200: {
    slidesPerView: 2.7,
    spaceBetween: 0,
  },
  768: {
    slidesPerView: 2.2,
    spaceBetween: 0,
  },

  550: {
    slidesPerView: 2.2,
    spaceBetween: 0,
  },
  400: {
    slidesPerView: 1.4,
    spaceBetween: 0,
  },
  320: {
    slidesPerView: 1.1,
    spaceBetween: 0,
  },
};

  return (
    <>
       <section>
           <Row>
               <Col xl="7" lg="7" md="12" sm="12" className='pe-0'>
                 <div className=''>
                  <Swiper
                      modules={[Pagination]}
                      spaceBetween={0}
                      slidesPerView={3}
                      breakpoints={breakpoints}
                      pagination={true}
                    >
                      <SwiperSlide>
                        <CategoryCard title='Hair Growth' imageUrl={CategorImg} link='/shop' />
                      </SwiperSlide>
                      <SwiperSlide>
                        <CategoryCard title='Skin Care' imageUrl={CategorImg1} link='/shop' />
                      </SwiperSlide>
                      <SwiperSlide>
                        <CategoryCard title='Health & Wellbeing' imageUrl={CategorImg2} link='/shop' />
                      </SwiperSlide>
                    </Swiper>
                 </div>
                </Col>

                <Col xl="5" lg="5" md="12" sm="12" className='ps-0'>
                   <VideoCard contents={contentData} />
               </Col>
           </Row>
       </section>
    </>
  )
}

export default Category
