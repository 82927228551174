import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import "./custom_filter.css"

const CustomeFilter = ({ sortOptions }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false); 
  };

  return (
    <div className="custom-select position-relative">
      <label className="label-style">
        Sort
      </label>
      <div className="select-selected" onClick={() => setIsOpen(!isOpen)}>
        {selectedOption ? selectedOption.label : "Select"} 
      </div>
      <div className="select-arrow">
        <IoIosArrowDown />
      </div>

      {isOpen && (
        <div className="select-items">
          {sortOptions.map((option) => (
            <div
              key={option.label}
              className="select-item"
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomeFilter;
