import React from 'react';
import ProductImage from '../../assets/product/product-2.png';
import Dropdown from 'react-bootstrap/Dropdown';


export default function ViewCartProduct() {
  return (
    <>

      {/* for one time Purchase */}
        <div className='view-cart-product'>
            
          <div className='cart-prod-img'>
              <img src={ProductImage} alt="product" />
          </div>
          
          <div className='cart-prod-name'>
              <div className='cart-rod-detail'>
                    <h3>PRO Appetite~Craving Control 1 Oz</h3>
                    <p className='text-success'><b>In stock</b></p>
                    <p><span><b>Categories :</b> Health &amp; Wellness</span> , <span><b> Item Code :</b> APPET</span></p>
                    
                    <p><span><b>Unit :</b> liquid</span> , <span><b>Weight :</b>  1 oz</span></p>
                    <div className='cart-qty d-sm-flex align-items-center mt-2'>
                      <input type="text" className='form-control mb-2 mb-sm-0' placeholder='No. Of Quantity'  style={{width:'150px'}}/>
                      <button type="btn"  className="cart-info-btn  ms-sm-3">Remove </button>
                      <span className='mx-3' style={{color:'#7777778c'}}> | </span>
                      <button type="btn" className="cart-info-btn">Save For Later </button>
                  </div>
              </div>
              <div className='prod-price'>
                <p className='text-custom-end text-large-start'><b>$816.00</b></p>
              </div>
          </div>
        </div>
      {/*End one time Purchase */}

      {/* for Subscription  Purchase */}
      <div className='view-cart-product'>
          <div className='cart-prod-img'>
              <img src={ProductImage} alt="product" />
          </div>
          
          <div className='cart-prod-name'>
              <div className='cart-rod-detail'>
                    <h3>PRO Appetite~Craving Control 1 Oz</h3>
                    <p className='text-success'><b>In stock</b></p>
                    <p><span><b>Categories :</b> Health &amp; Wellness</span> , <span><b> Item Code :</b> APPET</span></p>
                    
                    <p><span><b>Unit :</b> liquid</span> , <span><b>Weight :</b>  1 oz</span></p>
                    <div className='cart-qty  d-sm-flex  align-items-center mt-2'>
                      <input type="text" className='form-control' placeholder='No. Of Quantity'  style={{width:'150px'}}/>
                      <button type="btn"  className="cart-info-btn  ms-sm-3">Remove </button>
                      <span className='mx-3' style={{color:'#7777778c'}}> | </span>
                      <button type="btn" className="cart-info-btn">Save For Later </button>
                  </div>
              </div>
              <div className='prod-price position-relative'>
                <p className='text-custom-end text-large-start'><b>$816.00</b></p>
                 <p className='fa-15 text-large-start'>5% Subscribe & Save discount applied. </p>
                  <div className="dropdown text-custom-end text-large-start">
                     <Dropdown>
                     <span>Deliver every: </span>
                      <Dropdown.Toggle id="dropdown-basic" className='transparent-btn'>
                        Month
                      </Dropdown.Toggle>

                      <Dropdown.Menu className='custom-dropdown-style'>
                        <Dropdown.Item className='mb-1'>1 Month</Dropdown.Item>
                        <Dropdown.Item >2 Month</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
              
              </div>
          </div>
      </div>
      {/*End one time Purchase */}

      {/* sub total */}

      <div className='sub-total text-end mt-3'>
        <span className="sc-number-of-items"> Subtotal (2 items): </span>
           <span><b>$ 1,365.00</b></span>
      </div>


    </>
  )
}
