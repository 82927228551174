import React from 'react';
import LinkCustom from '../../../../shared/LinkCustom';
import "./category-card.css";

const CategoryCard = ({ title, imageUrl, link }) => {
  return (
    <div className='cate-card'>
      <div className="cate-item imgstyle position-relative">
        <img src={imageUrl} alt={title} className="img-fluid" />
        <div className="categ-content">
          <h1>{title}</h1>
          <LinkCustom
            outline={true}
            outlineColor="danger"
            radius={true}
            color="info"
            text="Shop Now"
            link={link}
            textColor="#E2000F"
            hoverTextColor="#fff"
            backgroundColor="#fff"
            hoverBackgroundColor="#E2000F"
          />
        </div>
        <div className="cate-overlay cate-bottom-overlay"> </div>
      </div>
    </div>
  );
};

export default CategoryCard;
