import React from "react";

const CouponCard = () => {
  return (
    <>
      <div className="coupon-list d-flex flex-nowrap  gap-3 pb-3">
        <div className="d-flex flex-column coupon-card-width ">
          <p className="mb-1 text-center">Extra 10% Coupon</p>
          <span className="badge bg-success">FSLCRT10</span>
        </div>

        <div className="d-flex flex-column  coupon-card-width ">
          <p className="mb-1 text-center">Extra 15% Coupon</p>
          <span className="badge bg-success">FSLCRT15</span>
        </div>
        <div className="d-flex flex-column  coupon-card-width ">
          <p className="mb-1 text-center">Extra 15% Coupon</p>
          <span className="badge bg-success">FSLCRT15</span>
        </div>
      </div>
    </>
  );
};

export default CouponCard;
