import React, { useState } from "react";
import { Col, Form } from "react-bootstrap";
import Select from "react-select";

function SocialMediaSelect() {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [attributes, setAttributes] = useState({});

  const options = [
    { value: "facebook", label: "Facebook" },
    { value: "instagram", label: "Instagram" },
    { value: "linkedin", label: "LinkedIn" },
    { value: "twitter", label: "Twitter" },
    { value: "tiktok", label: "Tiktok" },
    { value: "others", label: "Others" },
  ];

  const handleSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    setAttributes({});
  };



  const renderInputFields = () => {
    if (!selectedOptions || selectedOptions.length === 0) return null;

    return selectedOptions.map((option) => {
      switch (option.value) {
        case "facebook":
          return (
            <Form.Group as={Col} md="12" className="mb-3" key={option.value}>
              <Form.Control type="text" placeholder="Facebook URL" />
            </Form.Group>
          );
        case "instagram":
          return (
            <Form.Group as={Col} md="12" className="mb-3" key={option.value}>
              <Form.Control type="text" placeholder="Instagram URL" />
            </Form.Group>
          );
        case "linkedin":
          return (
            <Form.Group as={Col} md="12" className="mb-3" key={option.value}>
              <Form.Control type="text" placeholder="LinkedIn URL" />
            </Form.Group>
          );
        case "twitter":
          return (
            <Form.Group as={Col} md="12" className="mb-3" key={option.value}>
              <Form.Control type="text" placeholder="Twitter URL" />
            </Form.Group>
          );
        case "tiktok":
          return (
            <Form.Group as={Col} md="12" className="mb-3" key={option.value}>
              <Form.Control type="text" placeholder="Tiktok URL" />
            </Form.Group>
          );
        case "others":
          return (
            <Form.Group as={Col} md="12" className="mb-3" key={option.value}>
              <Form.Control type="text" placeholder="Others" />
            </Form.Group>
          );
        default:
          return null;
      }
    });
  };

  return (
    <div>
      
      <Form.Label>
      Social Media <span className="text-danger">* </span>
      </Form.Label>
      <Select
        value={selectedOptions}
        onChange={handleSelectChange}
        options={options}
        isMulti
        className="mb-3"
      />
      {renderInputFields()}
    </div>
  );
}

export default SocialMediaSelect;
