import React from "react";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Col, Image, Row } from "react-bootstrap";
import MediaImg from "../../../assets/media/tnn-logo.jpeg";
import MediaImg2 from "../../../assets/media/today-logo.png";
import MediaImg3 from "../../../assets/media/todayhoda-logo.webp";
import MediaImg4 from "../../../assets/media/nbc-logo-1.png";
import MediaImg5 from "../../../assets/media/STGEORGES.png";
import MediaImg6 from "../../../assets/media/RG_logo.png";
import MediaImg7 from "../../../assets/media/bernews.jpg";
import MediaImg8 from "../../../assets/media/CDA-LOGO.jpeg";
import MediaImg9 from "../../../assets/media/GOVbermuda-logo.png";
import "./media.css";

const Media = () => {
  return (
    <>
      <section className="ptop-5">
        <div className="side-space">
          <Row>
            <Col lg="12" md="12" sm="12" xs="12">
              <h3 className="common-heading mb-3">Media </h3>
            </Col>
          </Row>
          <Row className="m-auto justify-content-center">
            {/* product List */}
            <Col xs lg="12" md="12" className="px-0">
              <Swiper
                modules={[Navigation]}
                spaceBetween={50}
                slidesPerView={13}
                navigation={{
                  prevEl: ".swiper-custom-button-prev",
                  nextEl: ".swiper-custom-button-next",
                }}
                pagination={{ clickable: false }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log("slide change")}
                breakpoints={{
                  0: {
                    slidesPerView: 2.4,
                    spaceBetween: 10,
                  },

                  400: {
                    slidesPerView: 3.2,
                    spaceBetween: 10,
                  },

                  500: {
                    slidesPerView: 4.5,
                    spaceBetween: 10,
                  },

                  600: {
                    slidesPerView: 5,
                    spaceBetween: 10,
                  },

                  800: {
                    slidesPerView: 6,
                    spaceBetween: 10,
                  },
                  992: {
                    slidesPerView: 9,
                    spaceBetween: 20,
                  },

                  1399: {
                    slidesPerView: 10,
                    spaceBetween: 20,
                  },

                  1600: {
                    slidesPerView: 10,
                    spaceBetween: 20,
                  },
                }}
              >
                <SwiperSlide>
                  <div className="media-card-logo">
                    <Image src={MediaImg} className="img-fluid"></Image>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="media-card-logo">
                    <Image src={MediaImg2} className="img-fluid"></Image>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="media-card-logo">
                    <Image src={MediaImg3} className="img-fluid"></Image>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="media-card-logo">
                    <Image src={MediaImg4} className="img-fluid"></Image>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="media-card-logo">
                    <Image src={MediaImg5} className="img-fluid"></Image>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="media-card-logo">
                    <Image src={MediaImg6} className="img-fluid"></Image>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="media-card-logo">
                    <Image src={MediaImg7} className="img-fluid"></Image>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="media-card-logo">
                    <Image src={MediaImg8} className="img-fluid"></Image>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="media-card-logo">
                    <Image src={MediaImg9} className="img-fluid"></Image>
                  </div>
                </SwiperSlide>
              </Swiper>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default Media;
