import React from 'react';
import { FaStar , FaHeart , FaFacebookF  , FaTwitter  , FaLinkedin } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { Link } from 'react-router-dom';


const ProductContent = () => {
  return (
    <>
      <div className='prod-detail-card'>
          <h3>Adult Daily Multivitamin Gummies - (30 Day Supply)</h3>
          <p><b>Categories :</b> Health & Wellbeing</p>
          <div className='mb-4'>
            <span class="rating-box fa-14 p-2">4.5  <FaStar className="yellow-fill" />  | 0 Ratings  |
             <Link className='sky-blue-text ms-1 text-decoration-none' href="/#fill-tab-example-tabpane-review"><b> Post Your Review </b></Link>  </span>
          </div>
           <hr></hr>
          <p className='prod-price-text pt-1 mb-2'><span className='line-through opacity-75'> $39.99</span> &nbsp; &nbsp;<span>  $32.99</span> </p>
          <p className='mb-2'><b>Unit :</b> Capsules</p>
          <p className='mb-2'><b>Weight :</b> 100 Caps</p>

          <div className='mt-2'>
            <button className="btn-wishlist" title="Add to wishlist"> <FaHeart className='pr-2' />
             &nbsp; Add to Wishlist
            </button>
 </div>

           <div class="product_socials">
           <span class="label product_socials_label">
           Share :</span>
           <div class="socials-list">
              <ul className='socials-inline'>
                 <li> <Link to="#"><FaFacebookF /> 

</Link></li>
                 <li> <Link to="#"><FaTwitter /></Link></li>
                 <li> <Link to="#"><FaLinkedin />
</Link></li>
                 <li> <Link to="#"><IoLogoWhatsapp />
</Link></li>
              </ul>
          </div> 
          </div>
      </div>
    </>
  )
}

export default ProductContent
