import React from 'react'
import AfterBeforeGallery from "../../../shared/AfterBeforGallery";
import BeforeGaleryImg from "../../../assets/gal-1.jpg"
import AftereGaleryImg from "../../../assets/gal-2.jpg";
import { Swiper, SwiperSlide  } from 'swiper/react';
import { Navigation} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/autoplay';

import { IoIosArrowBack , IoIosArrowForward  } from "react-icons/io";


const UserExperiance = () => {
  const imageDataSet = [
    { beforeUrl: BeforeGaleryImg , afterUrl: AftereGaleryImg , showExperience: true, description:`I was telling myself, I don't think my hair is going to grow back' but then I tried Advanced Trichology
    and it did. If you are thinking about it, definitely come to Advanced Trichology`, name:'– Nicolette Grooms'},
    { beforeUrl: BeforeGaleryImg , afterUrl: AftereGaleryImg , showExperience: true, description:`I was telling myself, I don't think my hair is going to grow back' but then I tried Advanced Trichology
    and it did. If you are thinking about it, definitely come to Advanced Trichology`, name:'– Nicolette Grooms'},
    { beforeUrl: BeforeGaleryImg , afterUrl: AftereGaleryImg , showExperience: true, description:`I was telling myself, I don't think my hair is going to grow back' but then I tried Advanced Trichology
    and it did. If you are thinking about it, definitely come to Advanced Trichology`, name:'– Nicolette Grooms'},
    { beforeUrl: BeforeGaleryImg , afterUrl: AftereGaleryImg , showExperience: true, description:`I was telling myself, I don't think my hair is going to grow back' but then I tried Advanced Trichology
    and it did. If you are thinking about it, definitely come to Advanced Trichology`, name:'– Nicolette Grooms'},
    { beforeUrl: BeforeGaleryImg , afterUrl: AftereGaleryImg , showExperience: true, description:`I was telling myself, I don't think my hair is going to grow back' but then I tried Advanced Trichology
    and it did. If you are thinking about it, definitely come to Advanced Trichology`, name:'– Nicolette Grooms'},
  ];

  const breakpoints = {
    1299: {
      slidesPerView: 4,
      spaceBetween: 15,
    },
    992: {
      slidesPerView: 3,
      spaceBetween: 15,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 15,
    },
    500: {
      slidesPerView: 1.8,
      spaceBetween: 15,
    },
    320: {
      slidesPerView: 1.1,
      spaceBetween: 15,
    },
  };

  return (
    <>
        <section className='bg-light-gray ptop-5 pbottom-5'>
         <div className='side-space'>
            <div className='row align-items-center'>
              <div className='col-lg-12 col-md-12'>
                    <div className='gallery-content ps-0 ps-md-3'>
                        <h3>Real Customer Experiences  </h3>

                        <p className='mb-5 mt-1 m-auto gallery-para-width text-center'>Eliquam magna massa, gravida finibus fermentum sit amet Suspendisse consequat lacus libero. In ultricies gravida Maecenas nec erat sit amet eros venenatis ultricies.</p>
                    </div>
                  </div>
                <div className='col-lg-12 col-md-12'>
                   <div>

                     <Swiper
                      modules={[Navigation]}
                      spaceBetween={10}
                      slidesPerView={4}
                      breakpoints={breakpoints}
                      navigation={{
                        prevEl: '.swiper-silder-button-prev',
                        nextEl: '.swiper-silder-button-next',
                    }}
                   
                    >
                      {imageDataSet.map((imageData, index) => (
                        <SwiperSlide key={index}>
                          <AfterBeforeGallery galleryData={imageData} />
                        </SwiperSlide>
                      ))}
                    </Swiper>

                    <div className='position-relative'>
                      <div className='navigation-userexp  d-flex align-items-center justify-content-between'>
                            <div className="swiper-silder-button-prev"><IoIosArrowBack className='arrow-icon' /></div>
                            <div className="swiper-silder-button-next"><IoIosArrowForward className='arrow-icon'/> </div>
                        </div>
                    </div>
                   </div>
                </div>
               
            </div>
         </div>
      </section>
    </>
  )
}

export default UserExperiance
