import React from 'react';
import Category from './category/Category';
//import FlashSale from './flashsale/FlashSale';
import ImmunityBoosting from './ImmunityBoosting/ImmunityBoosting';
import Slider from './Slider/Slider';
import Banner from './Banner/Banner';
import UserExperiance from './userExpeiance/UserExperiance';
import TextBanner from './TextBanner/TextBanner';
import LatestProducts from './LatestProducts/LatestProducts';
import Store from './Store/Store';
import Media from './Media/Media';

const Home = () => {
  return (
    <>

      
     
      <Slider/> 

      <Banner/>

      <Category/>

      <UserExperiance/>
    
      <LatestProducts/>

      <ImmunityBoosting/>
      
       <TextBanner/>   

       <Media/>

       <Store/> 

    </>
  )
}

export default Home
