
import { useState } from 'react'
import { Swiper, SwiperSlide  } from 'swiper/react';
import { Navigation , Thumbs} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { IoIosArrowBack , IoIosArrowForward  } from "react-icons/io";
import ProdImg from "../../../assets/product/prod-1.png"
import ProdImg2 from "../../../assets/product/product-2.png"

const Slider = () => {
  const [activeThumb, handleSwiper] = useState()
      return (
        <>


<Swiper
        loop={true}
        spaceBetween={10}
        navigation={{
          prevEl: '.swiper-silder-button-prev',
          nextEl: '.swiper-silder-button-next',
      }}
        modules={[Navigation, Thumbs]}
        grabCursor={true}
        thumbs={{ swiper: activeThumb }}
        className='product-images-slider'
      >
        <SwiperSlide>
          <img src={ProdImg2} alt='product images' />
        </SwiperSlide>

        <SwiperSlide>
          <img src={ProdImg2} alt='product images' />
        </SwiperSlide>
       
      </Swiper>

      <Swiper
        onClick={handleSwiper}
        loop={false}
        spaceBetween={10}
        slidesPerView={4}
        modules={[Navigation, Thumbs]}
        className='product-images-slider-thumbs'  style={{width:'80px !important'}}
      >
        <SwiperSlide>
          <div className='product-images-slider-thumbs-wrapper'>
            <img src={ProdImg} alt='product images' />
          </div>
        </SwiperSlide>

        <SwiperSlide >
          <div className='product-images-slider-thumbs-wrapper'>
            <img src={ProdImg} alt='product images' />
          </div>
        </SwiperSlide>
       
      </Swiper>

           <div className='position-relative'>
            <div className='navigation-slide  d-flex align-items-center justify-content-between '>
                  <div className="swiper-silder-button-prev"><IoIosArrowBack  className='arrow-icon' /></div>
                  <div className="swiper-silder-button-next"><IoIosArrowForward  className='arrow-icon'/> </div>
              </div>
           </div>
         </>
      );
    };

export default Slider;
