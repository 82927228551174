import React from 'react';
import { Navigation} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Col, Image, Row } from 'react-bootstrap';
import StoreImg from "../../../assets/store/store-1.jpg";
import StoreImg2 from "../../../assets/store/clinet-2.jpeg";
import StoreImg4 from "../../../assets/store/clinet-4.jpeg";
import "./Store.css";
import { Link } from 'react-router-dom';


const Store = () => {
  return (
    <>
    
      <section className='ptop-5 pbottom-5'>
         <div className='side-space'>
            <Row>
                <Col lg="6" md="6" sm="6" xs="6">
                    <h3 className='common-heading mb-3'>Store </h3>
                </Col>

                <Col lg="6" md="6" sm="6" xs="6">

                    <div className='d-flex justify-content-end align-items-center gap-5 mb-3'>
                       <Link to="/find-store" className="view-all"> View All </Link>
                    </div>
                </Col>
            </Row>
            <Row>
                {/* product List */}
                <Col xs lg="12" md="12">
                    <Swiper
                        modules={[Navigation]}
                        spaceBetween={50}
                        slidesPerView={13}
                        navigation={{
                            prevEl: '.swiper-custom-button-prev',
                            nextEl: '.swiper-custom-button-next',
                        }}
                        pagination={{ clickable: false }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                        breakpoints={{
                           0: {
                              slidesPerView: 2.4,
                              spaceBetween: 10
                          },

                          400: {
                              slidesPerView: 3.2,
                              spaceBetween: 10
                           },

                           500: {
                              slidesPerView: 4.5,
                              spaceBetween: 10
                           },

                           600: {
                              slidesPerView:5,
                              spaceBetween: 10
                           },

                           800: {
                                slidesPerView: 6,
                                spaceBetween: 10
                            },
                            992: {
                                slidesPerView: 9,
                                spaceBetween: 20
                            },

                            1399: {
                                slidesPerView: 10,
                                spaceBetween: 20
                            },

                            1600: {
                                slidesPerView:11,
                                spaceBetween: 20
                            }
                        }} >
                       
                            <SwiperSlide>
                               <div className='store-card'>
                                  <Image src={StoreImg} className="img-fluid"></Image>
                               </div>
                               </SwiperSlide>

                               <SwiperSlide>
                               <div className='store-card'>
                                  <Image src={StoreImg} className="img-fluid"></Image>
                               </div>
                               </SwiperSlide>

                               <SwiperSlide>
                               <div className='store-card'>
                                  <Image src={StoreImg2} className="img-fluid"></Image>
                               </div>
                               </SwiperSlide>

                               <SwiperSlide>
                               <div className='store-card'>
                                  <Image src={StoreImg} className="img-fluid"></Image>
                               </div>
                               </SwiperSlide>
                               <SwiperSlide>
                               <div className='store-card'>
                                  <Image src={StoreImg4} className="img-fluid"></Image>
                               </div>
                               </SwiperSlide>

                               <SwiperSlide>
                               <div className='store-card'>
                                  <Image src={StoreImg4} className="img-fluid"></Image>
                               </div>
                               </SwiperSlide>

                               <SwiperSlide>
                               <div className='store-card'>
                                  <Image src={StoreImg4} className="img-fluid"></Image>
                               </div>
                               </SwiperSlide>

                               <SwiperSlide>
                               <div className='store-card'>
                                  <Image src={StoreImg} className="img-fluid"></Image>
                               </div>
                               </SwiperSlide>

                               <SwiperSlide>
                               <div className='store-card'>
                                  <Image src={StoreImg2} className="img-fluid"></Image>
                               </div>
                               </SwiperSlide>

                               <SwiperSlide>
                               <div className='store-card'>
                                  <Image src={StoreImg} className="img-fluid"></Image>
                               </div>
                               </SwiperSlide>
                               <SwiperSlide>
                               <div className='store-card'>
                                  <Image src={StoreImg4} className="img-fluid"></Image>
                               </div>
                               </SwiperSlide>

                               <SwiperSlide>
                               <div className='store-card'>
                                  <Image src={StoreImg} className="img-fluid"></Image>
                               </div>
                               </SwiperSlide>

                               <SwiperSlide>
                               <div className='store-card'>
                                  <Image src={StoreImg4} className="img-fluid"></Image>
                               </div>
                               </SwiperSlide>
                               
                      
                    </Swiper>
                    
                   
                </Col>
            </Row>
        </div>
    </section>
    </>
  )
}

export default Store
