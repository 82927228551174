import React from "react";
import { CiDeliveryTruck } from "react-icons/ci";
import { PiSealCheckLight } from "react-icons/pi";
import { CiCreditCard1 } from "react-icons/ci";
import { CiShop } from "react-icons/ci";

const ProductInfoCard = () => {
  return (
    <>
      <div className="mad-icon-boxes">
        <div className="mad-col">
          <article className="mad-icon-box">
            <i className="mad-icon-box-icon">
              <CiDeliveryTruck />
            </i>
            <div className="mad-icon-box-content">
              <h6 className="mad-icon-box-title">Fast Delivery</h6>
              <p>Within 1-4 business days</p>
            </div>
          </article>
        </div>
        <div className="mad-col">
          <article className="mad-icon-box">
            <i className="mad-icon-box-icon">
              <PiSealCheckLight />
            </i>
            <div className="mad-icon-box-content">
              <h6 className="mad-icon-box-title">Return &amp; Refund</h6>
              <p>30 days return policy</p>
            </div>
          </article>
        </div>
        <div className="mad-col">
          <article className="mad-icon-box">
            <i className="mad-icon-box-icon">
              <CiCreditCard1 />
            </i>
            <div className="mad-icon-box-content">
              <h6 className="mad-icon-box-title">Safe Shopping</h6>
              <p>100% secure payment</p>
            </div>
          </article>
        </div>
        <div className="mad-col">
          <article className="mad-icon-box">
            <i className="mad-icon-box-icon">
              <CiShop />
            </i>
            <div className="mad-icon-box-content">
              <h6 className="mad-icon-box-title">Click &amp; Collect</h6>
              <p>In any of our stores</p>
            </div>
          </article>
        </div>
      </div>
    </>
  );
};

export default ProductInfoCard;
