import React from 'react';
import AfterBeforGallery from "../../shared/AfterBeforGallery";
import BeforeGaleryImg from "../../assets/gal-1.jpg";
import AftereGaleryImg from "../../assets/gal-2.jpg";
import "./clinical_result.css"

const ClinicalResults = () => {
    const galleryData = [
        { beforeUrl: BeforeGaleryImg , afterUrl: AftereGaleryImg , showExperience: false,},
        { beforeUrl: BeforeGaleryImg , afterUrl: AftereGaleryImg , showExperience: false,},
        { beforeUrl: BeforeGaleryImg , afterUrl: AftereGaleryImg , showExperience: false,},
        { beforeUrl: BeforeGaleryImg , afterUrl: AftereGaleryImg , showExperience: false,},
        { beforeUrl: BeforeGaleryImg , afterUrl: AftereGaleryImg , showExperience: false,},
        { beforeUrl: BeforeGaleryImg , afterUrl: AftereGaleryImg , showExperience: false,},
        { beforeUrl: BeforeGaleryImg , afterUrl: AftereGaleryImg , showExperience: false,},
        { beforeUrl: BeforeGaleryImg , afterUrl: AftereGaleryImg , showExperience: false,},
        { beforeUrl: BeforeGaleryImg , afterUrl: AftereGaleryImg , showExperience: false,},
        { beforeUrl: BeforeGaleryImg , afterUrl: AftereGaleryImg , showExperience: false,},
        { beforeUrl: BeforeGaleryImg , afterUrl: AftereGaleryImg , showExperience: false,},
        { beforeUrl: BeforeGaleryImg , afterUrl: AftereGaleryImg , showExperience: false,},
        { beforeUrl: BeforeGaleryImg , afterUrl: AftereGaleryImg , showExperience: false,},
        { beforeUrl: BeforeGaleryImg , afterUrl: AftereGaleryImg , showExperience: false,},
        { beforeUrl: BeforeGaleryImg , afterUrl: AftereGaleryImg , showExperience: false,},
    ]
  return (
    <>
      <section className="ptop-5 pbottom-5">
            <div className="side-space">
                 <div className='gallery-list'>
                       {galleryData.map((galleryData, index) => (
                           <AfterBeforGallery galleryData={galleryData} key={index}/>
                        ))}
                 </div>
            </div>
        </section>
    </>
  )
}

export default ClinicalResults

