import React, { useState } from 'react';
import CustomLink from '../../../shared/LinkCustom';
import './addtocart.css';
import Quantity from './Quantity';
import Form from 'react-bootstrap/Form';
import CustomButton from '../../../shared/CustomButton';

function AddToCart() {
  const [activeItem, setActiveItem] = useState('item1');

  const handleItemClick = (itemId) => {
    setActiveItem(itemId);
  };

  return (
    <div className="accordion pl-40 mt-3 mt-lg-0">
      <div className="accordion-item">
        <input
          type="radio"
          name="accordion"
          id="item1"
          className='ms-2'
          checked={activeItem === 'item1'}
          onChange={() => handleItemClick('item1')} style={{zoom: '1.6'}}
        />
        <label htmlFor="item1" className="accordion-label">
          One Time Purchase<p className='mb-0 dark-brown-text'>US $88.00  </p>
        </label>
        <div className="content">
           <div>
              <Quantity/>

               <div className='mt-3 w-100 d-flex'>
                 <CustomLink   border={true}
                    borderWidth={3}
                    rounded={true} 
                    borderColor="#3c3436"
                    link="/cart"
                    text="Add To Card"
                    textColor="#fff"
                    hoverTextColor="#3C3436"
                    backgroundColor="#3C3436"
                    hoverBackgroundColor="#fff"/>
               </div>
           </div>
        </div>
      </div>
      <div className="accordion-item">
        <input
          type="radio"
          name="accordion"
          id="item2"
          link="/cart"
          className='ms-2'
          checked={activeItem === 'item2'}
          onChange={() => handleItemClick('item2')} style={{zoom: '1.6'}}
        />
         <label htmlFor="item2" className="accordion-label">
         Subscribe & Save<p className='mb-0 dark-brown-text'>US $79.20  <span className='line-through ms-3'>$88.00</span>  </p>
        </label>
        <div className="content">
            <div>
             <h3 className='text-green'>4 In Stock</h3>
              <Quantity/>
               
                <div className='mt-3'>
                <Form.Label htmlFor="delivery">Delivery Every</Form.Label>
                  <Form.Select style={{padding:'0.75rem 1rem'}}>
                    <option value="1">1 Month</option>
                    <option value="2">2 Month</option>
                    <option value="3">3 Month</option>
                    </Form.Select>
                </div>

               <div className='mt-3'>
                 <CustomButton  border={false}
                    borderWidth={0}
                    rounded={true} 
                    color="#ffd814"
                    text="Subscribe Now & Buy"
                    textColor="#000"
                    hoverTextColor="#000"
                    backgroundColor="#ffd814"
                    hoverBackgroundColor="#ffc107"/>
                </div>  
                <p className='mb-1 mt-3'>Monthly Auto-deliveries sold by Shopnmac</p>
                <p className='text-green mb-1'><b>Save 10%</b></p>
                <h4 className='mb-0'>On subscription with 1 Months</h4>
             </div>
            
        </div>
      </div>
    </div>
  );
}

export default AddToCart;
