import React from 'react'
import { Col, Row } from 'react-bootstrap';
import ProductContent from './component/ProductContent';
import ProductSlider from "./component/ProductSlider"
import "./product_details.css";
import  AddToCart from  "./component/AddToCart"
import ProductTab from './component/ProductTab';
import { Navigation} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import ProdImg from "../../assets/product/prod-1.png";
import ProductCard from '../../shared/ProductCard';
import { LuMoveRight , LuMoveLeft } from "react-icons/lu";
import ProductInfoCard from './component/ProductInfoCard';

const ProductDetail = () => {

  const product = [
    {
      title: "Hair Growth Combo with Rx M ",
      price: 20,
      oldPrice: 25,
      imageSrc: ProdImg,
      rating: 4.5,
      inStock: true,
      hairClubMember: ``,
    },
    {
      title: "Hair Growth Combo with Rx M ",
      price: 20,
      oldPrice: 25,
      imageSrc: ProdImg,
      rating: 4.5,
      inStock: true,
    },
    {
      title: "Hair Growth Combo with Rx M ",
      price: 20,
      oldPrice: 25,
      imageSrc: ProdImg,
      rating: 4.5,
      inStock: true,
    },
    {
      title: "Hair Growth Combo with Rx M ",
      price: 20,
      oldPrice: 25,
      imageSrc: ProdImg,
      rating: 4.5,
      inStock: true,
    },
    {
      title: "Hair Growth Combo with Rx M ",
      price: 20,
      oldPrice: 25,
      imageSrc: ProdImg,
      rating: 4.5,
      inStock: true,
    },
    {
      title: "Hair Growth Combo with Rx M ",
      price: 20,
      oldPrice: 25,
      imageSrc: ProdImg,
      rating: 4.5,
      inStock: false,
      hairClubMember: ``,
    },
    {
      title: "Hair Growth Combo with Rx M ",
      price: 20,
      oldPrice: 25,
      imageSrc: ProdImg,
      rating: 4.5,
      inStock: true,
    },
    {
      title: "Hair Growth Combo with Rx M ",
      price: 20,
      oldPrice: 25,
      imageSrc: ProdImg,
      rating: 4.5,
      inStock: true,
    },
    {
      title: "Hair Growth Combo with Rx M ",
      price: 20,
      oldPrice: 25,
      imageSrc: ProdImg,
      rating: 4.5,
      inStock: false,
    },
    {
      title: "Hair Growth Combo with Rx M ",
      price: 20,
      oldPrice: 25,
      imageSrc: ProdImg,
      rating: 4.5,
      inStock: true,
    },
    {
      title: "Hair Growth Combo with Rx M ",
      price: 20,
      oldPrice: 25,
      imageSrc: ProdImg,
      rating: 4.5,
      inStock: true,
    },
    {
      title: "Hair Growth Combo with Rx M ",
      price: 20,
      oldPrice: 25,
      imageSrc: ProdImg,
      rating: 4.5,
      inStock: false,
    },
    ];
  return (
    <>
       <section className="ptop-5 pbottom-5">
        <div className="side-space">
          <Row>
            <Col lg="4" md="6" sm="12">
                <ProductSlider/>
            </Col>

           <Col lg="8" md="6" sm="12">
             <Row>
              <Col xl="8" lg="7" md="12">
                  <ProductContent/>
                   {/* visible from abover tablet device*/}
                  <div className='mt-5 d-none d-lg-block'>
                     <ProductInfoCard/>
                  </div>
                   {/*end  visible from abover tablet device */}
              </Col>

              <Col xl="4" lg="5" md="12">
                <AddToCart/>
                 
                 {/* visible from after tablet device*/}
                  <div className='mt-5 d-block d-lg-none'>
                     <ProductInfoCard/>
                  </div>
                  {/*end  visible from tablet */}
              </Col>
             </Row>
           </Col>
           </Row>
            
            <hr className='my-4 my-sm-5'></hr>
           <Row>
              <Col xl="9" lg="12">
                  <ProductTab/>
              </Col>
           </Row>
        </div>
    </section>

    <hr/>

    <section className='pt-5 pbottom-5'>
         <div className='side-space'>
            <Row>
                <Col lg="6" md="6" sm="6" xs="12">
                    <h3 className='common-heading mb-3'>Customers Also Brought  </h3>
                </Col>

                <Col lg="6" md="6" sm="6" xs="12">
                    <div className='d-flex justify-content-end align-items-center mb-3 mb-sm-0'>
                        <div className='navigation-style position-relative d-flex align-items-center'>
                            <div className="swiper-custom-button-prev"><LuMoveLeft className='arrow-icon' /></div>
                            <div className="swiper-custom-button-next"><LuMoveRight className='arrow-icon'/> </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                {/* product List */}
                <Col xs lg="12" md="12">
                    <Swiper
                        modules={[Navigation]}
                        spaceBetween={50}
                        slidesPerView={3}
                        navigation={{
                            prevEl: '.swiper-custom-button-prev',
                            nextEl: '.swiper-custom-button-next',
                        }}
                        pagination={{ clickable: false }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                        breakpoints={{
                          0: {
                            slidesPerView:1.5,
                            spaceBetween: 10
                           },
                          400: {
                            slidesPerView:2,
                            spaceBetween: 10
                           },

                          600: {
                            slidesPerView:2.7,
                            spaceBetween: 10
                           },

                          700: {
                            slidesPerView:3.3,
                            spaceBetween: 10
                           },

                          800: {
                            slidesPerView:4,
                            spaceBetween: 10
                           },
                            1000: {
                                slidesPerView:4.3,
                                spaceBetween: 10
                            },
                            1200: {
                                slidesPerView: 5.3,
                                spaceBetween: 20
                            },

                            1366: {
                                slidesPerView: 6.2,
                                spaceBetween: 20
                            },

                            1550: {
                                slidesPerView: 7.3,
                                spaceBetween: 20
                            }
                        }} 
                         >
                        {product.map((product, index) => (
                            <SwiperSlide key={index}>
                                <ProductCard product={product} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    
                   
                </Col>
            </Row>
        </div>
    </section>
    </>
  )
}

export default ProductDetail
