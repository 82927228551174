import React from 'react';
import "./video_section.css"

const VideoSection = () => {
  return (
    <div className="video-section position-relative overflow-hidden">
      <div className="overlay_video">
        <video playsInline autoPlay muted loop className="d-flex" width="100%">
          <source src="https://drbrownslab.com/assets/web/images/banner-video/video.mp4" type="video/mp4" />
          <source src="https://drbrownslab.com/assets/web/images/banner-video/video.ogv" type="video/ogv" />
          <source src="https://drbrownslab.com/assets/web/images/banner-video/video.webm" type="video/webm" />
          Sorry, your browser doesn't support embedded videos.
        </video>
      </div>
      <div className="video-content-parent">
        <div className="content-child">
          <div className="h2-head">The Perfect</div>
          <h1 className="h1-head mt-3 mb-2">Doctor-formulated Brand</h1>
          <p className="para-head mb-0">For Your Health, Hair And Skin!</p>
        </div>
      </div>
    </div>
  );
}

export default VideoSection;
