import React from 'react';
import { Link } from 'react-router-dom';
import { FaLongArrowAltRight } from 'react-icons/fa';
import '../blog.css';
import { Image } from 'react-bootstrap';

const BlogCard = ({ blogData }) => {
  return (
    <>
        <div className="blog-inner mb-3">
          <Link to={blogData.link} title="Read More">
            <div className="blog-img">
              <Image src={blogData.image} alt="" fluid />
            </div>
          </Link>
          <div className="blog-overlay">
            <div className="blog-date-info">
              <p className="mb-0">{blogData.category}</p>
              <span className="blogcard-date">{blogData.date}</span>
            </div>
            <div className="blog-text">
              <div className="bdl-text">
                <Link to={blogData.link} title="Read More">
                  <h1>{blogData.title}</h1>
                </Link>
                <div>
                  <Link to={blogData.link} title="Read More">
                    <FaLongArrowAltRight />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
     
    </>
  );
};

export default BlogCard;
