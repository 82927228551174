import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProdImg from "../../assets/product/prod-1.png";
import ProductCard from "../../shared/ProductCard";
import "./shop.css";
import CustomeFilter from "../../shared/CustomFilter";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import Pagination from "./component/Pagination";
import PriceRangeFilter from "./component/PriceRangeFilter";
import Checkbox from "../../shared/Checkbox";
import { FaStar, FaRegStar } from "react-icons/fa";

const Shop = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const product = [
    {
      title: "Hair Growth Combo with Rx M ",
      price: 20,
      oldPrice: 25,
      imageSrc: ProdImg,
      rating: 4.5,
      inStock: true,
      hairClubMember: ``,
    },
    {
      title: "Hair Growth Combo with Rx M ",
      price: 20,
      oldPrice: 25,
      imageSrc: ProdImg,
      rating: 4.5,
      inStock: true,
    },
    {
      title: "Hair Growth Combo with Rx M ",
      price: 20,
      oldPrice: 25,
      imageSrc: ProdImg,
      rating: 4.5,
      inStock: true,
    },
    {
      title: "Hair Growth Combo with Rx M ",
      price: 20,
      oldPrice: 25,
      imageSrc: ProdImg,
      rating: 4.5,
      inStock: true,
    },
    {
      title: "Hair Growth Combo with Rx M ",
      price: 20,
      oldPrice: 25,
      imageSrc: ProdImg,
      rating: 4.5,
      inStock: true,
    },
    {
      title: "Hair Growth Combo with Rx M ",
      price: 20,
      oldPrice: 25,
      imageSrc: ProdImg,
      rating: 4.5,
      inStock: false,
      hairClubMember: ``,
    },
    {
      title: "Hair Growth Combo with Rx M ",
      price: 20,
      oldPrice: 25,
      imageSrc: ProdImg,
      rating: 4.5,
      inStock: true,
    },
    {
      title: "Hair Growth Combo with Rx M ",
      price: 20,
      oldPrice: 25,
      imageSrc: ProdImg,
      rating: 4.5,
      inStock: true,
    },
    {
      title: "Hair Growth Combo with Rx M ",
      price: 20,
      oldPrice: 25,
      imageSrc: ProdImg,
      rating: 4.5,
      inStock: false,
    },
    {
      title: "Hair Growth Combo with Rx M ",
      price: 20,
      oldPrice: 25,
      imageSrc: ProdImg,
      rating: 4.5,
      inStock: true,
    },
    {
      title: "Hair Growth Combo with Rx M ",
      price: 20,
      oldPrice: 25,
      imageSrc: ProdImg,
      rating: 4.5,
      inStock: true,
    },
    {
      title: "Hair Growth Combo with Rx M ",
      price: 20,
      oldPrice: 25,
      imageSrc: ProdImg,
      rating: 4.5,
      inStock: false,
    },
  ];

  const sortOptions = [
    { label: "Frequently", value: "Frequently" },
    { label: "Recommended", value: "Recommended" },
    { label: "Featured", value: "Featured" },
    { label: "Popular", value: "Popular" },
    { label: "Latest", value: "Latest" },
  ];
  return (
    <>
      <section className="ptop-5 pbottom-5">
        <div className="side-space">
          <Row className="mb-3">
            <Col lg="6" md="6" sm="6" xs="12">
              <button className="btn-gray" onClick={toggleAccordion}>
                <HiOutlineAdjustmentsHorizontal className="me-1 adjust-icon" />{" "}
                Filter{" "}
              </button>
              <span className="show-result ms-3">
                Showing 1–18 of 36 results
              </span>
            </Col>

            <Col lg="6" md="6" sm="6" xs="12">
              <div className="d-flex justify-content-end align-items-center mt-3 mt-sm-0">
                <CustomeFilter sortOptions={sortOptions}></CustomeFilter>
              </div>
            </Col>

            <Col xl="12" lg="12" >
              <div
                className={`accordion-content mt-3 ${
                  isOpen ? "open" : "closed"
                }`}
              >
                <Row className="">
                  <Col xl="3" lg="4" md="6" sm="12">
                    <h3 className="filter-heading">Product Categories</h3>
                    <div className="availability-filter mb-3">
                      <Checkbox label=" Hair Growth" />
                      <Checkbox label="Skin Care" />
                      <Checkbox label="Health & Wellbeing" />
                    </div>
                  </Col>

                  <Col xl="3" lg="4" md="6" sm="12">
                    <div className="mb-3">
                      <PriceRangeFilter />
                    </div>
                  </Col>

                  <Col xl="3" lg="4" md="6" sm="12">
                    <h3 className="filter-heading">By Rating</h3>
                    <div className="availability-filter mb-3">
                      <div className="d-flex gap-2">
                        <Checkbox /> <FaStar className="yellow-fill" />{" "}
                        <FaStar className="yellow-fill" />
                        <FaStar className="yellow-fill" />{" "}
                        <FaStar className="yellow-fill" />{" "}
                        <FaStar className="yellow-fill" />
                      </div>
                      <div className="d-flex gap-2">
                        <Checkbox /> <FaStar className="yellow-fill" />{" "}
                        <FaStar className="yellow-fill" />
                        <FaStar className="yellow-fill" />{" "}
                        <FaStar className="yellow-fill" />{" "}
                        <FaRegStar className="yellow-fill" />
                      </div>

                      <div className="d-flex gap-2">
                        <Checkbox /> <FaStar className="yellow-fill" />{" "}
                        <FaStar className="yellow-fill" />
                        <FaStar className="yellow-fill" />{" "}
                        <FaRegStar className="yellow-fill" />{" "}
                        <FaRegStar className="yellow-fill" />
                      </div>

                      <div className="d-flex gap-2">
                        <Checkbox /> <FaStar className="yellow-fill" />{" "}
                        <FaStar className="yellow-fill" />
                        <FaRegStar className="yellow-fill" />{" "}
                        <FaRegStar className="yellow-fill" />{" "}
                        <FaRegStar className="yellow-fill" />
                      </div>

                      <div className="d-flex gap-2">
                        <Checkbox /> <FaStar className="yellow-fill" />{" "}
                        <FaRegStar className="yellow-fill" />
                        <FaRegStar className="yellow-fill" />{" "}
                        <FaRegStar className="yellow-fill" />{" "}
                        <FaRegStar className="yellow-fill" />
                      </div>
                    </div>
                  </Col>

                  <Col xl="3" lg="4" md="6" sm="12">
                    <h3 className="filter-heading">Availability</h3>
                    <div className="availability-filter mb-3">
                      <Checkbox label="In Stock" />
                      <Checkbox label="Out of Stock" />
                      <Checkbox label=" Exclude: On backorder" />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            {/* product List */}
            <Col xs lg="12" md="12">
              <div className="product-list">
                {product.map((product, index) => (
                  <ProductCard product={product} />
                ))}
              </div>

              <div className="mt-4 text-center d-flex justify-content-around">
                <Pagination />
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default Shop;
