import React from 'react'
import { Col, Row } from 'react-bootstrap';
import LinkCustom from '../../shared/LinkCustom';
import HairClubForm from "./component/HairClubForm";
import { BsArrowRight } from "react-icons/bs";
import AfterBeforGallery from "../../shared/AfterBeforGallery";
import BeforeGaleryImg from "../../assets/gal-1.jpg";
import AftereGaleryImg from "../../assets/gal-2.jpg";
import "./hair_club.css";

const HairClub = () => {

    const galleryData = [
        { beforeUrl: BeforeGaleryImg , afterUrl: AftereGaleryImg , showExperience: false,},
        { beforeUrl: BeforeGaleryImg , afterUrl: AftereGaleryImg , showExperience: false,},
        { beforeUrl: BeforeGaleryImg , afterUrl: AftereGaleryImg , showExperience: false,},
        { beforeUrl: BeforeGaleryImg , afterUrl: AftereGaleryImg , showExperience: false,},
    ]

  return (
    <>
       <section className="ptop-5">
         <div className='side-space'>
            <Row>
                <Col xl="8" lg="7" md="12" xs="12" >
                    <div className='hairclub-container'>
                        <h2 className='heading-h1'>HAIR CLUB FOR MEN & WOMEN</h2>
                        <p>Dolor sit amet consectetur adipiscing elit duis tristique sollicitudin. 
                             Risus viverra <br></br> adipiscing at in tellus integer feugiat scelerisque varius.</p>

                        <div className='mt-4 mb-4'>
                        <ul className="hairclub-list">
                        <li><BsArrowRight className='me-2 text-dark font-20'/> Send images and then receive a treatment recommendation by Dr. Brown</li>
                        <li><BsArrowRight className='me-2 text-dark font-20'/> Have access to the Prescription Strength formulations</li>
                        <li><BsArrowRight className='me-2 text-dark font-20'/>  Monthly recurrent order of products either delivered or collected from Northshore Medical Center</li>
                        <li><BsArrowRight className='me-2 text-dark font-20'/> Discount of other services offered </li>
                    </ul>
                        </div>
                        <h4>Access Your Account if you are already registered in Hair Club </h4>

                        <div className='pt-3'>
                        <LinkCustom
                            outline={true}
                            outlineColor="danger"
                            radius={true}
                            color="#fff"
                            text="Login To Your Account "
                            link="/login"
                            textColor="#fff"
                            hoverTextColor="#fff"
                            backgroundColor="#C75E0A"
                            hoverBackgroundColor="#3C3436"
                        />
                        </div>

                    </div>
                    <div className='mt-5 mb-5'>
                     <HairClubForm/>
                    </div>
                    </Col>
                    <Col  xl="4" lg="5" md="12" xs="12">
                        <div className='hair-club-gallery'>
                        <h3 className='common-heading mb-3 text-left'>3 MONTHS RESULT OF HAIR RESTORATION</h3>
                         <div className="hair-club-grid">
                            {galleryData.map((galleryData, index) => (
                                <AfterBeforGallery galleryData={galleryData} key={index}/>
                            ))}
                         </div>
                    </div>   
                </Col>
            </Row>

         </div>
      
        
      </section>
    </>
  )
}

export default HairClub
