import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';

function Checkbox({ label }) {
  const [checked, setChecked] = useState();

  const handleCheckboxChange = (e) => {
    setChecked(e.target.checked);
  };

  return (
    <>
      <Form.Check
        label={label}
        checked={checked}
        id={label}
        onChange={handleCheckboxChange}
      />
    </>
  );
}

export default Checkbox;
