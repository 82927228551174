import React from 'react';
import ProductImage from '../../assets/product/prod-1.png';


export default function CheckoutProduct() {
  return (
    <>
        <div className="table-responsive">
            <table className="table table-borderd table-border rounded border" width="100%">
                <thead>
                    <tr className='bg-dark text-white'>
                        <th>Image</th>
                        <th>Product</th>
                        <th>Price ($)</th>
                        <th>Quantity</th>
                        <th className="text-right">Total($)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><img src={ProductImage} alt="Product" className='prod-width'/></td>
                        <td>Test New</td>
                        <td>$0.05</td>
                        <td>1</td>
                        <td className="text-right">US $0.05</td>
                    </tr>

                    <tr>
                        <td  colspan="4" className="text-right fa-18x theme-text-color" style={{background:'#f1f1f1'}}><b>Cart Total</b></td>
                        <td className="text-right" style={{background:'#f1f1f1'}}><b>US $0.05</b></td>
                    </tr>

                    <tr  style={{background:'#f1f1f1'}}>
                        <td colspan="4" className="text-right fa-18x theme-text-color" style={{background:'#f1f1f1'}}><b>Shipping Charge</b></td>
                        <td className="text-right" style={{background:'#f1f1f1'}}><b>US $0</b></td>
                    </tr>

                    <tr style={{background:'#f1f1f1'}}>
                        <td colspan="4" className="text-right fa-18x theme-text-color" style={{background:'#f1f1f1'}}><b>Total Pay</b></td>

                        <td className="text-right" style={{background:'#f1f1f1'}}><b>US $0.05</b></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </>
  )
}
