import { BrowserRouter as Router, Routes, Route , Outlet } from 'react-router-dom';
import './App.css';
import Home from './webpages/Home/Home';
import Shop from './webpages/Shop/Shop';
import Header from "./layout/header/Header";
import Footer from "./layout/footer/Footer";
import ProductDetail from './webpages/ProductDetail/ProductDetail';
import HairClub from './webpages/HairClub/HairClub';
import HairMasterClass from './webpages/HairMasterClass/HairMasterClass';
import Cart from './webpages/Cart/Cart';
import SignInSignUp from "./webpages/SignInSignUp/SignInSignUp"
import ForgotPassword from './webpages/SignInSignUp/ForgotPassword';
import AffiliateRegistration from "./webpages/AffiliateRegistration/AffiliateRegistration"
import ClinicalResults from './webpages/ClinicalResults/ClinicalResults';
import Checkout from './webpages/Checkout/Checkout';
import StoreAt from './webpages/StoreAt/StoreAt';
import AboutUs from './webpages/AboutUs/AboutUs';
import ContactUs from "./webpages/ContactUs/Contactus"
import Blog from './webpages/Blog/Blog';
import BlogDetails from './webpages/BlogDetails/BlogDetails';
import Payment from "./webpages/Payment/Payment"
import TestimonialForm from './webpages/Testimonial/TestimonialForm';
import TrackOrder from './webpages/TrackOrder/TrackOrder';
import Faq from './webpages/Faq/Faq';
import Deal from './webpages/Deal/Deal';

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Outlet />}>
            <Route index element={<Home />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/product-details" element={<ProductDetail />} />
            <Route path="/hair-club" element={<HairClub/>} />
            <Route path="/hair-master-class" element={<HairMasterClass/>} />
            <Route path="/affiliate-registration" element={<AffiliateRegistration/>} />
            <Route path="/login" element={<SignInSignUp/>} />
            <Route path="/forgot-password" element={<ForgotPassword/>} />
            <Route path="/cart" element={<Cart/>} />
            <Route path="/clinical-results" element={<ClinicalResults/>} />
            <Route path="/checkout" element={<Checkout/>} />
            <Route path="/find-store" element={<StoreAt/>} />
            <Route path="/about-us" element={<AboutUs/>} />
            <Route path="/contact-us" element={<ContactUs/>} />
            <Route path="/blog" element={<Blog/>} />
            <Route path="/blog-details" element={<BlogDetails/>} />
            <Route path="/payment" element={<Payment/>} />
            <Route path="/testimonial" element={<TestimonialForm/>} />
            <Route path="/track-order" element={<TrackOrder/>} />
            <Route path="/faq" element={<Faq/>} />
            <Route path="/deal" element={<Deal/>} />

          </Route>
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
