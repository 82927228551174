import React, { useState } from "react";
import {  Form, Card,  InputGroup, Col,  Row,} from "react-bootstrap";
import { Link } from "react-router-dom";
import CustomButton from "../../shared/CustomButton";

const PaymentCard = () => {
  const [paymentMethod, setPaymentMethod] = useState("");
  const [creditCardDetails, setCreditCardDetails] = useState({
    cardNumber: "",
    expiryDate: "",
    cvv: "",
  });

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };


  return (
      <Card>
        <Card.Body>
          <h3 className="mb-3 common-heading"> Choose Payment Mode </h3>
          <hr></hr>
          <Form>
            <Form.Group>
              <Form.Check
                type="radio"
                label="UPI"
                id="UPI"
                value="upi"
                className="mb-3"
                checked={paymentMethod === "upi"}
                onChange={handlePaymentMethodChange}
              />
              {paymentMethod === "upi" && (
                <>
                  <div className="payment-box my-3">
                    <h3>Pay with UPI</h3>
                    <hr></hr>
                    <div>
                        Option Here
                    </div>
                  </div>
                </>
              )}
              <Form.Check
                type="radio"
                label="Credit / Debit Card"
                id="credit_card"
                value="credit_card"
                className="mb-3"
                checked={paymentMethod === "credit_card"}
                onChange={handlePaymentMethodChange}
              />
              {paymentMethod === "credit_card" && (
                <>
                  <div className="payment-box my-3">
                    <h3>Credit/ Debit Card </h3>
                    <hr></hr>
                    <div>
                      <Row>
                        <Col xl={6} lg={12} md="12">
                          <Form.Group className="mb-3">
                            <Form.Label>Card Holder First Name</Form.Label>
                            <Form.Control
                              type="text"
                              name="cardFirstName"
                              placeholder="Card Holder First name"
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col xl={6} lg={12} md="12">
                          <Form.Group className="mb-3">
                            <Form.Label>Card Holder Last Name</Form.Label>
                            <Form.Control
                              type="text"
                              name="cardLastName"
                              placeholder="Card Holder Last name"
                              required
                            />
                          </Form.Group>
                        </Col>

                        <Col xl="12" lg={12} md="12">
                          <Form.Group className="mb-3">
                            <Form.Label>Credit Card Number</Form.Label>
                            <Form.Control
                              type="text"
                              name="cardNumber"
                              placeholder="Enter Credit Card Number"
                              required
                            />
                          </Form.Group>
                        </Col>

                        <Col xl={6} lg={6} md={6}  xs="6" className="pe-0">
                          <Form.Group className="mb-3">
                            <Form.Label>Expiry Date</Form.Label>
                            <Form.Control as="select" name="month" required>
                              <option value="">Month</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md={6}  xs="6">
                          <Form.Group className="mb-3">
                            <Form.Label>Year</Form.Label>
                            <Form.Control as="select" name="year" required>
                              <option value="">Year</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>

                        <Col xl={6} lg={12}   xs="12">
                          <Form.Group className="mb-3">
                            <Form.Label>CVC</Form.Label>
                            <Form.Control
                              type="password"
                              name="cvc"
                              placeholder="Enter CCV"
                              required
                            />
                          </Form.Group>
                        </Col>

                        <Col xl={6} lg={12}  xs="12">
                          <Form.Group className="mb-3">
                            <Form.Label>Security Code</Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                placeholder="Enter Code →"
                                aria-describedby="basic-addon2"
                              />
                              <InputGroup.Text id="basic-addon2">
                                {" "}
                                1234
                              </InputGroup.Text>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col md={12}>
                          <Form.Group className="custom-control custom-checkbox mb-3">
                            <Form.Check
                              type="checkbox"
                              id="agreeTerms"
                              label={
                                <span className="fa-18x text-theme fw-500">
                                  By proceeding with this purchase, I
                                  acknowledge that I have read and agree to the
                                  <Link to="#" className="ancher-text">
                                    &nbsp;
                                    <b>terms and conditions</b>
                                  </Link>{" "}
                                  of use
                                </span>
                              }
                              name="agreeTerms"
                            />
                          </Form.Group>
                        </Col>

                        <Col xs="12">
                        <CustomButton  border={false}
                            borderWidth={0}
                            rounded={true} 
                            color="#ffd814"
                            text="Pay Now"
                            textColor="#fff"
                            hoverTextColor="#fff"
                            backgroundColor="#3c3436"
                            hoverBackgroundColor="#554f51"/>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </>
              )}

              <Form.Check
                type="radio"
                label="Authorize.net"
                id="authorize.net"
                value="authorize.net"
                className="mb-3"
                checked={paymentMethod === "authorize.net"}
                onChange={handlePaymentMethodChange}
              />
              {paymentMethod === "authorize.net" && <></>}
            </Form.Group>
            <div className="mt-3">
              <CustomButton  border={false}
                borderWidth={0}
                rounded={true} 
                color="#ffd814"
                text="Pay Now"
                textColor="#fff"
                hoverTextColor="#fff"
                backgroundColor="#3c3436"
                hoverBackgroundColor="#554f51"/>
            </div>
          </Form>
        </Card.Body>
      </Card>
  );
};

export default PaymentCard;
