import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";
import CustomButton from "../../../shared/CustomButton";

const HairClubForm = () => {
  return (
    <>
      <div className="hairclub-form">
        <h3 className="common-heading mb-4 text-center text-white">
          Join Hair Club{" "}
        </h3>
        <Form className="form form-bg ">
          <Row className="mb-3">
            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                First Name <span className="text-danger">* </span>
              </Form.Label>
              <Form.Control type="tex" placeholder="Enter First Name" />
            </Form.Group>

            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                Last Name <span className="text-danger">* </span>
              </Form.Label>
              <Form.Control type="tex" placeholder="Enter Last Name" />
            </Form.Group>

            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                Date of Birth <span className="text-danger">* </span>
              </Form.Label>
              <Form.Control type="date" placeholder="Date of Birth" />
            </Form.Group>

            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                Gender <span className="text-danger">* </span>
              </Form.Label>
              <Form.Select>
                <option value="1">Male</option>
                <option value="2">Female</option>
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                Mobile <span className="text-danger">* </span>
              </Form.Label>
              <Form.Control type="text" placeholder="Enter Mobile" />
            </Form.Group>

            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                Address <span className="text-danger">* </span>
              </Form.Label>
              <Form.Control type="text" placeholder="Enter Your Address " />
            </Form.Group>

            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                Country <span className="text-danger">* </span>
              </Form.Label>
              <Form.Select as={Col} md="6" className="mb-3">
                <option value="">Select Country</option>
                <option value="Afghanistan">Afghanistan</option>
                <option value="Åland Islands">Åland Islands</option>
                <option value="Albania">Albania</option>
                <option value="Algeria">Algeria</option>
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                Email <span className="text-danger">* </span>
              </Form.Label>
              <Form.Control type="email" placeholder="Enter Email " />
            </Form.Group>

            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                Confirm Email <span className="text-danger">* </span>
              </Form.Label>
              <Form.Control type="email" placeholder="Confirm Email" />
            </Form.Group>

            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                {" "}
                Password <span className="text-danger">* </span>
              </Form.Label>
              <Form.Control type="password" placeholder="Password" />
            </Form.Group>

            <Form.Group as={Col} md="12" className="mb-3">
              <Form.Label>
                Insurance <span className="text-danger">* </span>
              </Form.Label>
              <Form.Select as={Col} md="6" className="mb-3">
                <option value="">Select an Option</option>
                <option value="3">Argus</option>
                <option value="2">BFM</option>
                <option value="1">Colonial</option>
                <option value="5">Future Care</option>
                <option value="4">HIP</option>
                <option value="6">Other</option>
                <option value="0">None</option>
              </Form.Select>
            </Form.Group>

            <Col xs="12" className="mb-3">
              <h4 className="hairclub-heading">Medical History : </h4>
              <hr style={{ borderColor: "#fff" }} />
            </Col>

            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                Do you have a family history of hair loss?{" "}
                <span className="text-danger">* </span>
              </Form.Label>
              <Form.Select as={Col} md="6" className="mb-3">
                <option value="">Select</option>
                <option value="Mother or Father">Mother or Father</option>
                <option value="Aunts">Aunts</option>
                <option value="Uncles">Uncles</option>
                <option value="Grandparents">Grandparents</option>
                <option value="Siblings">Siblings</option>
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                Where is the hair loss more prominent?{" "}
                <span className="text-danger">* </span>
              </Form.Label>
              <Form.Select as={Col} md="6" className="mb-3">
                <option value="">Select</option>
                <option value="Top of Scalp">Top of Scalp</option>
                <option value="Sides">Sides</option>
                <option value="Back of Scalp">Back of Scalp</option>
                <option value="Small circular patches">
                  Small circular patches
                </option>
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                At what age did your hair loss start?{" "}
                <span className="text-danger">* </span>
              </Form.Label>
              <Form.Control type="text" placeholder="Leave Comment" />
            </Form.Group>

            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                {" "}
                Do you have an itching on the scalp?{" "}
                <span className="text-danger">* </span>
              </Form.Label>
              {["radio"].map((type) => (
                <div key={`inline-${type}`} className="mb-3">
                  <Form.Check
                    inline
                    label="Yes"
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                  />
                  <Form.Check
                    inline
                    label="No"
                    name="group1"
                    type={type}
                    id={`inline-${type}-2`}
                  />
                </div>
              ))}
            </Form.Group>

            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                {" "}
                Is your scalp dry and flaky?{" "}
                <span className="text-danger">* </span>
              </Form.Label>
              {["radio"].map((type) => (
                <div key={`inline-${type}`} className="mb-3">
                  <Form.Check
                    inline
                    label="Yes"
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                  />
                  <Form.Check
                    inline
                    label="No"
                    name="group1"
                    type={type}
                    id={`inline-${type}-2`}
                  />
                </div>
              ))}
            </Form.Group>

            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                Do you have any razer bumps?
                <span className="text-danger">* </span>
              </Form.Label>
              {["radio"].map((type) => (
                <div key={`inline-${type}`} className="mb-3">
                  <Form.Check
                    inline
                    label="Yes"
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                  />
                  <Form.Check
                    inline
                    label="No"
                    name="group1"
                    type={type}
                    id={`inline-${type}-2`}
                  />
                </div>
              ))}
            </Form.Group>

            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                {" "}
                Do you have hair falling out in large clumps?{" "}
                <span className="text-danger">* </span>
              </Form.Label>
              {["radio"].map((type) => (
                <div key={`inline-${type}`} className="mb-3">
                  <Form.Check
                    inline
                    label="Yes"
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                  />
                  <Form.Check
                    inline
                    label="No"
                    name="group1"
                    type={type}
                    id={`inline-${type}-2`}
                  />
                </div>
              ))}
            </Form.Group>

            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                What are you styling practices?{" "}
                <span className="text-danger">* </span>
              </Form.Label>
              {["checkbox"].map((type) => (
                <div className="mb-3">
                  <Form.Check
                    inline
                    label="Use Styling Products"
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                  />
                  <br />
                  <Form.Check
                    inline
                    label="Use Perms or Chemical relaxers"
                    name="group1"
                    type={type}
                    id={`inline-${type}-2`}
                  />

                  <Form.Check
                    inline
                    label="Use colors or highlights"
                    name="group1"
                    type={type}
                    id={`inline-${type}-2`}
                  />
                </div>
              ))}
            </Form.Group>

            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                How often do you wash your hair?{" "}
                <span className="text-danger">* </span>
              </Form.Label>
              <Form.Select as={Col} md="6" className="mb-3">
                <option value="">Select</option>
                <option value="Daily">Daily</option>
                <option value="Once a week">Once a week</option>
                <option value="Twice a week">Twice a week</option>
                <option value="Every other week">Every other week</option>
                <option value="Once per month">Once per month</option>
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                What is your dietary preference?{" "}
                <span className="text-danger">* </span>
              </Form.Label>
              <Form.Select as={Col} md="6" className="mb-3">
                <option value="">Select</option>
                <option value="Vegan">Vegan</option>
                <option value="Vegetarian">Vegetarian</option>
                <option value="Keto">Keto</option>
                <option value="Normal western diet">Normal western diet</option>
                <option value="Other">Other</option>
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} md="12" controlId="formFile" className="mb-3">
              <Form.Label>
                Upload Face Image <span className="text-danger">* </span>
              </Form.Label>
              <Form.Control type="file" />
            </Form.Group>

            <Form.Group as={Col} md="12" controlId="formFile" className="mb-3">
              <Form.Label>
                Scalp Image 1 <span className="text-danger">* </span>
              </Form.Label>
              <Form.Control type="file" />
            </Form.Group>

            <Form.Group as={Col} md="12" controlId="formFile" className="mb-3">
              <Form.Label>Scalp Image 3</Form.Label>
              <Form.Control type="file" />
            </Form.Group>

            <Form.Group as={Col} md="12" className="mb-3">
              <Form.Label>
                {" "}
                Security Code <span className="text-danger">* </span>
              </Form.Label>
              <InputGroup>
                <Form.Control placeholder="Enter Code → " />
                <InputGroup.Text id="basic-addon2"> 13456 </InputGroup.Text>
              </InputGroup>
            </Form.Group>

            <Col xs="12">
              <CustomButton
                outline={true}
                outlineColor="#3C3436"
                rounded={true} 
                color="#fff0"
                text="JOIN NOW"
                textColor="#fff"
                hoverTextColor="#3C3436"
                backgroundColor="#C75E0A"
                hoverBackgroundColor="#fff"
              />
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default HairClubForm;
