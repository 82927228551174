import React from 'react';
import {Form } from 'react-bootstrap';
import CouponCard from '../../shared/CouponCard';
import CustomButton from '../../shared/CustomButton';
import './Coupon.css';

export default function Coupon() {
  return (
    <>
      <div className="discount-code mb-3">
            <h3>Coupon Discount</h3>
            <p>Enter your coupon code if you have one.</p>
            <div>
            <Form className='mt-3'>
                   <div className='coupon-row'>
                   <div className='w-100'>
                          <Form.Control
                              type="text"
                              placeholder="Coupon code"
                              required
                            />
                    </div>
                    <div className='coupon-width'>
                       <CustomButton  border={false}
                            borderWidth={0}
                            rounded={true} 
                            color="#ffd814"
                            text="Apply Coupon"
                            textColor="#fff"
                            hoverTextColor="#fff"
                            backgroundColor="#3c3436"
                            hoverBackgroundColor="#554f51"/>
                    </div>
                   </div>
            </Form>
            </div>

            <div className='mt-4 text-center' >
              <CouponCard/>
            </div>
        </div>
    </>
  )
}
