import React from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Store = ({ storeInfo }) => {
  const { name, address, phone, email, storeHours, imageUrl } = storeInfo;

  return (
    <>
      <div className='store-card pt-3 position-relative'>
        <div className='store-img'>
          <Image src={imageUrl} className="img-fluid" alt={name} />
        </div>
        <div className='store-info mt-3'>
          <h3 className='text-center'>{name}</h3>
          <div className="sold-details">
            <p><b className='fa-600'>Address : </b> {address}</p>
            <p><b className='fa-600'>Phone : </b> {phone}</p>
            <p><b className='fa-600'>Email : </b> <Link to={`mailto:${email}`}>{email}</Link></p>
            <div>
              <h3>Store Hours:</h3>
              {storeHours.map((hour, index) => (
                <p key={index}>{hour.day} : {hour.openTime} - {hour.closeTime}</p>
              ))}
            </div>
          </div>
          <div className='store-overlay store-bottom-overlay'></div>
        </div>
      </div>
    </>
  );
};

export default Store;
