import React, { useState } from 'react';
import "./quantity.css";

const Quantity = () => {
    const [quantity, setQuantity] = useState(1);
  
   
    const handleIncrement = () => {
      setQuantity(quantity + 1);
    };
  
    const handleDecrement = () => {
      if (quantity > 1) {
        setQuantity(quantity - 1);
      }
    };
  return (
    <>
      <div className="quantity-container">
        <div className="quantity-control">
          <button onClick={handleDecrement}>-</button>
          <div className='qty'>{quantity}</div>
          <button onClick={handleIncrement}>+</button>
        </div>
      </div>
    </>
  )
}

export default Quantity

