import React from 'react';
import { Form } from 'react-bootstrap';

export default function PickUpPoint() {
  return (
    <>
      <div className='pickuppoin-address'>
      <div className="custom-control custom-checkbox mb-3">
          <Form.Check 
            type="checkbox"
            id="customCheck"
            label={<b>Pick up your order from our pickup store.</b>}
          />
       </div>
          <p className='text-theme w-100'> Northshore Medical Center
            Salon B Hair & Scalp Clinic
            2nd Floor 7 Northshore Road
            Devonshire DV01</p>
      </div>
       
    </>
  )
}
