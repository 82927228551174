
import React from 'react';
import {Image} from "react-bootstrap";
import { FaStar } from "react-icons/fa";

const TestimonialCard = ({ testimonial }) => {
  return (
    <div className='testimonial-card'>
      <div className='testimonial-img'>
        <Image src={testimonial.imageUrl} alt="testimonial-img"  fluid />
      </div>
      <div className='testimonial-details'>
        <div className="d-flex justify-content-between mb-2">
          <span className="rating">
            {[...Array(testimonial.rating)].map((_, index) => (
              <span className="star-icon" key={index}><FaStar /></span>
            ))}
          </span>
          <span className="published">
            {testimonial.date}
          </span>
        </div>
        <p className='testimonial-desc mb-2'>{testimonial.description}</p>
        <p className="text-end mb-0"> <b>- {testimonial.author}</b></p>
      </div>
    </div>
  )
}

export default TestimonialCard;
