import React, { useState } from 'react';
import './gallery.css';
import { Image } from 'react-bootstrap';

const ImageSlider = ({ galleryData }) => {
  const [position, setPosition] = useState(50);

  const handleSliderChange = (e) => {
    setPosition(e.target.value);
  };

  return (
    <>
      <div className='main-slider'>
        <div className='container-slide'>
          <div className='image-box'>
            <Image
              className='image-before slider-image'
              src={galleryData.beforeUrl}
              alt='Before'
              width={500}
              height={500}
              style={{ width: `${position}%` }}
            />
            <input
              type='range'
              min='0'
              max='100'
              value={position}
              aria-label='Percentage of before photo shown'
              className='slider'
              onChange={handleSliderChange}
            />
            <div className='slider-line' style={{ left: `${position}%` }}></div>
            <div className='slider-button' style={{ left: `${position}%` }}>
              <span>Drag</span>
            </div>
            <Image
              className='image-after slider-image'
              src={galleryData.afterUrl}
              alt='After'
              width={500}
              height={500}
            />
          </div>
        </div>
        {galleryData.showExperience && ( 
          <div className='p-3 experince-content'>
            <p className='mt-2'>{galleryData.description}</p>
            <p className='text-end mt-2 mb-0'>{galleryData.name}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default ImageSlider;
