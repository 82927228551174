import React from "react";
import "./Payment.css";
import CheckoutProduct from "./CheckoutProduct";
import Coupon from "./Coupon";
import PaymentCard from "./PaymentCard";
import { Col, Row } from "react-bootstrap";

export default function Payment() {

 
  return (
    <>
        <section className="ptop-5 pbottom-5">
          <div className="side-space">
          
            <Row>
               <Col xl="7" lg="7" xs="12">
               <div className="coupon pe-xl-5">
               <div className="payment-address-deatils  d-block d-sm-flex justify-content-between">
                <div className="bill-deatil">
                  <h3 className="common-heading">Billing Address</h3>
                  <p className="mb-1">
                    <b>Jyotirmoy Sinha</b>
                  </p>
                  <p>
                    Dda Flat, Pocket 13, Dwarka Ph 1, Manglapuri, Palam
                    <br />
                    New Delhi, New Delhi 110045,
                    <br />
                    Bermuda
                  </p>
                  <p>
                    <b>Phone: </b>9999652812{" "}
                  </p>
                </div>

                <div className="bill-deatil">
                  <h3 className="common-heading"> Pickedup Address </h3>
                  <p>
                    Dr Brown's Laboratories Ltd <br /> P.O. Box HM1839,
                    <br /> Hamilton HMGX Bermuda{" "}
                  </p>
                </div>
              </div>

              <hr></hr>
                  {/* Coupon */}
                  <Coupon></Coupon>
                  {/* Coupon */}
                </div>
               </Col>
               <Col xl="5" lg="5" xs="12">
                <div className="checkout-product">
                    {/* CheckoutProduct */}
                    <CheckoutProduct></CheckoutProduct>
                    {/* End CheckoutProduct */}
                  </div>

                  <div className="card-details mt-3 mt-lg-0">
                     <PaymentCard></PaymentCard>
                  </div>
               </Col>
            </Row>
           
          </div>
        </section>
    </>
  );
}
