import React from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import  {Button}  from 'react-bootstrap';
import { FaSearch } from "react-icons/fa";
import './search.css';

export default function SearchComponent() {
  return (
    <>
    
        <InputGroup>
            <InputGroup.Text className='border-end-0 bg-transparent'><Button variant="link" className='btn-custom'> <FaSearch /> </Button>  </InputGroup.Text>
            <Form.Control placeholder="Search..." className='custom-input border-start-0' />
        </InputGroup>
     
    </>
  )
}
