import React from 'react'
import { Col, Image, Row } from 'react-bootstrap';
import HairMasterForm from "./component/HairMasterForm";
import "../HairClub/hair_club.css"
import MasterClassBanner from "../../assets/master-class.jpg"

const HairMasterClass = () => {

  return (
    <>
       <section className="ptop-5 pbottom-5">
         <div className='side-space'>
            <Row className='m-auto'>
                <Col xs="12" lg="8" className='m-auto'>
                    <div className='hairclub-container'>
                        <h2 className='heading-h1 text-center'>MISSED IT? <span className='text-borwn'>SIGN-UP</span> HERE TO LEARN ABOUT THE NEXT MASTERCLASS</h2>
                        <p className='text-center'>These classes are preferred for salon owners, cosmetologists, hair stylists and barbers. If you qualify for these courses, complete this form so we can add you to our email list and send you updates for upcoming courses or opportunities. Any questions, you can also email <b>info@drbrownslaboratory.com</b></p>
                    </div>
                   
                    </Col>
              </Row>

            <Row className='mt-3 mt-md-5 '>
                <Col xs="12" lg="7">
                    <div>
                        <Image src={MasterClassBanner} alt="Master Class Banner" className="img-fluid mb-3 mb-lg-0"/>
                    </div>
                </Col>

                <Col xs="12" lg="5">
                    <div>
                       <HairMasterForm/>
                    </div>
                </Col>
            </Row>

         </div>
      
        
      </section>
    </>
  )
}

export default HairMasterClass
