import React from 'react';
import { Image } from 'react-bootstrap';

const SideBlog = ({ blog }) => {
  return (
    <div className="blog-recent mb-3">
      <div className="recent-blog-img">
        <Image src={blog.image} fluid alt={blog.title} />
      </div>
      <h4 className='line-clamp-3'>{blog.title}</h4>
    </div>
  );
};

export default SideBlog;
