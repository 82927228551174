import React from 'react';
import { Form} from 'react-bootstrap';
import CustomButton from '../../shared/CustomButton';
import "./CheckoutForm.css";
import {Link} from "react-router-dom"

const CheckoutForm = () => {
    return (
        <Form>
            <div className='checkout-form mt-3 mb-3'>
                <div className="d-block d-sm-flex justify-content-between mt-3 mb-2">
                    <p className='common-heading'> Contact information  </p>
                    <p className="pull-right text-theme  float-sm-none">Already have an account? <Link to="/login" className="ancher-text"><b>Login</b></Link></p>
                </div>

                <div className="outer-box">
                    <div className="inner-box">
                        <Form.Group className="mb-3">
                            <Form.Label>Email <span className="text-danger">*</span></Form.Label>
                            <Form.Control type="email" name="new_email"  placeholder="Enter Email" required />
                        </Form.Group>
                    </div>
                </div>

                <div className="d-block d-sm-flex justify-content-between mt-4 mb-4">
                    <p className='checkout-heading'> Add a new address</p>
                </div>

                <div className="outer-box mt-3 mb-3">
                    <div className="inner-box">
                        <Form.Group className="mb-3">
                            <Form.Label>Full Name <span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text"  placeholder="Full Name" required />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Phone Number <span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text" placeholder="Enter Phone Number" minLength="10" maxLength="13" required />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Address <span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text" placeholder="Address Line 1" />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Flate, House No., Building , Company, Apartment, floor,</Form.Label>
                            <Form.Control type="text"  placeholder="Apartment, suite, unit, building, floor, etc." />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>City / Parish <span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text"  placeholder="Enter City / Parish" required />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Postal Code <span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text" placeholder="Postal Code" required />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label> State <span className="text-danger"></span>*</Form.Label>
                            <Form.Control type="text"  placeholder="State" required />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Country/Region <span className="text-danger">*</span></Form.Label>
                            <Form.Select  required>
                                <option value="">Select Country</option>
                                <option value="Afghanistan">Afghanistan</option>
                                <option value="Åland Islands">Åland Islands</option>
                                <option value="Albania">Albania</option>
                                <option value="Algeria">Algeria</option>
                                <option value="American Samoa">American Samoa</option>
                            </Form.Select>
                        </Form.Group>

                        <div className="text-center mt-4 mb-2">
                      
                            <CustomButton
                                border={false}
                                borderWidth={0}
                                rounded={true}
                                color="#ffd814"
                                text="Add Address"
                                textColor="#fff"
                                hoverTextColor="#fff"
                                backgroundColor="#C75E0A"
                                hoverBackgroundColor="#770A00"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    );
};

export default CheckoutForm;
