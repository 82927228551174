import React, { useState } from "react";
import "./signin.css";
import GoogleIcon from "../../assets/google.svg";
import AgentIcon from "../../assets/manager.png";
import FBIcon from "../../assets/facebook.png";

import { Link } from "react-router-dom";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import CustomButton from "../../shared/CustomButton";
import { Form } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";

const Login = () => {
  const [signInVisible, setSignInVisible] = useState(true);
  const [showAgentLoginForm, setShowAgentLoginForm] = useState(false);

  const togglePasswordVisibility = () => {
    const passwordInput = document.getElementById("password");

    if (passwordInput.type === "password") {
      passwordInput.type = "text";
    } else {
      passwordInput.type = "password";
    }
  };

  const showSignUpForm = () => {
    setSignInVisible(false);
  };

 

  const showSignInForm = () => {
    setSignInVisible(true);
    setShowAgentLoginForm(false);
  };

  const showAgentLogin = () => {
    setShowAgentLoginForm(true);
    setSignInVisible(false);
  };

  return (
    <div className="fullscreen ptop-5 pbottom-5">
      <div className="side-space">
        {signInVisible && !showAgentLoginForm ? (
          <SignInForm
            togglePasswordVisibility={togglePasswordVisibility}
            showSignUpForm={showSignUpForm}
            showAgentLogin={showAgentLogin}
          />
        ) : showAgentLoginForm ? (
          <AgentLoginForm showSignInForm={showSignInForm} />
        ) : (
          <SignUpForm showSignInForm={showSignInForm} />
        )}
      </div>
    </div>
  );
};

const SignInForm = ({ showSignUpForm, showAgentLogin }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };
  {
    /* U// Sign In Form */
  }

  return (
    <div className="login-container form-lable">
      <h2 className="text-center mb-4">Sign In</h2>

      {/* Google and Microsoft Login Buttons  */}

      <div className="pt-1 block d-md-flex gap-3">
        <button className="btn google-login-btn mt-3">
          <img src={GoogleIcon} width="36" height="36" alt="Google" />
          Login with Google
        </button>
        <button className="btn google-login-btn mt-3" onClick={showAgentLogin}>
          <img src={FBIcon} width="36" height="36" alt="Agent" />
          Login With Facebook
        </button>
      </div>

      {/* OR Separator  */}
      <div className="text-center mt-3 mb-4 or">
        <span className="or-style">OR</span>
      </div>

      <Form>
        <Form.Group className="mb-3" controlId="username">
          <Form.Control className="" type="text" placeholder="Name" required />
        </Form.Group>

        <Form.Group className="mb-3 position-relative" controlId="password">
          <Form.Control
            className=""
            type={passwordVisible ? "text" : "password"}
            placeholder="Password"
            required
          />
          <div className="password-icon" onClick={togglePasswordVisibility}>
            {passwordVisible ? <FaRegEyeSlash /> : <FaRegEye />}
          </div>
        </Form.Group>

        {/* Login Button  */}
        <div className="pt-3">
          <CustomButton
            border={false}
            borderWidth={0}
            rounded={true}
            color="#ffd814"
            text="Sign In"
            textColor="#fff"
            hoverTextColor="#000"
            backgroundColor="#C75E0A"
            hoverBackgroundColor="#CFD8DC"
          />
        </div>

        <div className="text-left mt-2 login-para">
          <Link to="/forgot-password">Forgot Password?</Link>
        </div>
      </Form>

      {/* OR Separator  */}
      <div className="text-center mt-3 mb-4 or">
      </div>

      {/* Google and Microsoft Login Buttons  */}

      <div className="pt-1 block d-md-flex gap-3 width-50 w-sm-100 m-auto">
        <button className="btn google-login-btn" onClick={showAgentLogin}>
          <img src={AgentIcon} width="36" height="36" alt="Agent" />
          Login As Sales Agent
        </button>
      </div>

      {/* Create new account  */}
      <div className="login-para mt-3 pt-1">
        <p>
          Don't have an account yet? <br></br>
          <div className="mt-3">
            <Link to="#" className="them-color-text" onClick={showSignUpForm}>
              Sign Up as a guest
            </Link>
            <span className="mx-2" style={{ color: "#f5f5f55e" }}>
              {" "}
              |{" "}
            </span>

            <Link to="/hair-club" className="them-color-text">
              Sign Up For Hair Club
            </Link>
            <span className="mx-2" style={{ color: "#f5f5f55e" }}>
              {" "}
              |{" "}
            </span>
            <Link to="/affiliate-registration" className="them-color-text">
              Become an affiliate
            </Link>
          </div>
        </p>
      </div>
    </div>
  );
};

// Sign Up Form

const SignUpForm = ({ showSignInForm }) => {
  return (
    <div className="login-container form-lable">
      <h2 className="text-center mb-4">Sign Up</h2>
      <Form>
        <Form.Group className="mb-3" controlId="name">
          <Form.Label>Full Name</Form.Label>
          <Form.Control
            className=""
            type="text"
            placeholder="Full Name"
            required
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="mobileNo">
          <Form.Label>Mobile No.</Form.Label>
          <Form.Control
            className=""
            type="text"
            placeholder="Mobile Number"
            required
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            className=""
            type="email"
            placeholder="Email"
            required
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="confirmEmail">
          <Form.Label>Confirm Email</Form.Label>
          <Form.Control
            className=""
            type="email"
            placeholder="Confirm your email"
            required
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="address">
          <Form.Label>Address</Form.Label>
          <Form.Control
            className=""
            type="text"
            placeholder="Your Address"
            required
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="country">
          <Form.Label>Country</Form.Label>
          <Form.Select className="custome-select-icon">
            <option value="">Select Country</option>
            <option value="Åland Islands">Åland Islands</option>
            <option value="Albania">Albania</option>
            <option value="Algeria">Algeria</option>
            <option value="American Samoa">American Samoa</option>
            <option value="Andorra">Andorra</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            className=""
            type="password"
            placeholder="Password"
            required
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="confirmPassword">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            className=""
            type="password"
            placeholder="Confirm Password"
            required
          />
        </Form.Group>

        <div className="pt-3">
          <CustomButton
            border={false}
            borderWidth={0}
            rounded={true}
            color="#ffd814"
            text="Sign Up"
            textColor="#fff"
            hoverTextColor="#000"
            backgroundColor="#C75E0A"
            hoverBackgroundColor="#CFD8DC"
          />
        </div>

        <div className="login-para mt-3">
          <p>
            Already have an account? &nbsp;
            <Link to="#" className="them-color-text" onClick={showSignInForm}>
              Sign In
            </Link>
          </p>
        </div>
      </Form>
    </div>
  );
};

// Sales Agent Login & Agent Registration

const AgentLoginForm = ({ showAgentLogin }) => {
  const [isRegistered, setIsRegistered] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleRegisterClick = () => {
    setIsRegistered(!isRegistered);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  if (isRegistered) {
    return (
      <div className="login-container form-lable">
        {/* Agent registration form */}
        <h2 className="text-center mb-4">Sales Agent Registration</h2>
        <Form>
          <Form.Group className="mb-3">
            <Form.Control type="text" required placeholder="Full Name" />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Control as="select" required>
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="mb-3 custom-date-input">
            <Form.Control type="date" required />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Control type="text" required placeholder="Mobile No." />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Control type="email" required placeholder="Email" />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Control type="password " required placeholder="Password " />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Control type="text" required placeholder="Address " />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Control type="text" required placeholder="City / Perish " />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Control type="text" required placeholder="Postal Code " />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Control type="text" required placeholder="State (Optional)" />
          </Form.Group>

          <Form.Group className="mb-3" controlId="country">
            <Form.Label>Country</Form.Label>
            <Form.Select className="">
              <option value="">Select Country</option>
              <option value="Åland Islands">Åland Islands</option>
              <option value="Albania">Albania</option>
              <option value="Algeria">Algeria</option>
              <option value="American Samoa">American Samoa</option>
              <option value="Andorra">Andorra</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Profile Photo (200px * 200px)</Form.Label>
            <Form.Control type="file" className="custom-file-input" />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>ID Proof Photo (200px * 200px)</Form.Label>
            <Form.Control type="file" className="custom-file-input" />
          </Form.Group>

          <InputGroup className="mb-3">
            <Form.Control placeholder="Security Code →" />
            <InputGroup.Text id="basic-addon2">6593</InputGroup.Text>
          </InputGroup>

          <div className="pt-3">
            <CustomButton
              border={false}
              borderWidth={0}
              rounded={true}
              color="#ffd814"
              text="Save"
              textColor="#fff"
              hoverTextColor="#000"
              backgroundColor="#C75E0A"
              hoverBackgroundColor="#CFD8DC"
            />
          </div>

          <div className="mt-2 login-para ">
            <p className="pull-right  login-text-agent float-sm-none">
              Already have an account?{" "}
              <Link
                to="#"
                className="them-color-text"
                onClick={handleRegisterClick}
              >
                Agent Login
              </Link>
            </p>
          </div>
        </Form>
      </div>
    );
  }

  return (
    <div className="login-container form-lable">
      <h2 className="text-center mb-4">Sales Agent Login</h2>
      <Form>
        <Form.Group className="mb-3" controlId="agentEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            className=""
            type="email"
            placeholder="Email"
            required
          />
        </Form.Group>

        <Form.Group
          className="mb-3 position-relative"
          controlId="agentPassword"
        >
          <Form.Control
            className=""
            type={passwordVisible ? "text" : "password"}
            placeholder="Password"
            required
          />
          <div className="password-icon" onClick={togglePasswordVisibility}>
            {passwordVisible ? <FaRegEyeSlash /> : <FaRegEye />}
          </div>
        </Form.Group>

        <div className="pt-3">
          <CustomButton
            border={false}
            borderWidth={0}
            rounded={true}
            color="#ffd814"
            text="Sign In as Agent"
            textColor="#fff"
            hoverTextColor="#000"
            backgroundColor="#C75E0A"
            hoverBackgroundColor="#CFD8DC"
          />
        </div>
        <div className="login-para mt-3">
          <p>
            Don't have an account yet? &nbsp;
            <Link
              to="#"
              className="them-color-text"
              onClick={handleRegisterClick}
            >
              Register as Agent
            </Link>
          </p>
        </div>
      </Form>
    </div>
  );
};

export default Login;
