import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProdImg from "../../assets/product/prod-1.png";
import ProductCard from "../../shared/ProductCard";


const Deal = () => {

  const product = [
    {
      title: "Hair Growth Combo with Rx M ",
      price: 20,
      oldPrice: 25,
      imageSrc: ProdImg,
      rating: 4.5,
      inStock: true,
      hairClubMember: ``,
    },
    {
      title: "Hair Growth Combo with Rx M ",
      price: 20,
      oldPrice: 25,
      imageSrc: ProdImg,
      rating: 4.5,
      inStock: true,
    },
    {
      title: "Hair Growth Combo with Rx M ",
      price: 20,
      oldPrice: 25,
      imageSrc: ProdImg,
      rating: 4.5,
      inStock: true,
    },
    {
      title: "Hair Growth Combo with Rx M ",
      price: 20,
      oldPrice: 25,
      imageSrc: ProdImg,
      rating: 4.5,
      inStock: true,
    },
    {
      title: "Hair Growth Combo with Rx M ",
      price: 20,
      oldPrice: 25,
      imageSrc: ProdImg,
      rating: 4.5,
      inStock: true,
    },
    {
      title: "Hair Growth Combo with Rx M ",
      price: 20,
      oldPrice: 25,
      imageSrc: ProdImg,
      rating: 4.5,
      inStock: false,
      hairClubMember: ``,
    },
    {
      title: "Hair Growth Combo with Rx M ",
      price: 20,
      oldPrice: 25,
      imageSrc: ProdImg,
      rating: 4.5,
      inStock: true,
    },
    {
      title: "Hair Growth Combo with Rx M ",
      price: 20,
      oldPrice: 25,
      imageSrc: ProdImg,
      rating: 4.5,
      inStock: true,
    },
    {
      title: "Hair Growth Combo with Rx M ",
      price: 20,
      oldPrice: 25,
      imageSrc: ProdImg,
      rating: 4.5,
      inStock: false,
    },
    {
      title: "Hair Growth Combo with Rx M ",
      price: 20,
      oldPrice: 25,
      imageSrc: ProdImg,
      rating: 4.5,
      inStock: true,
    },
    {
      title: "Hair Growth Combo with Rx M ",
      price: 20,
      oldPrice: 25,
      imageSrc: ProdImg,
      rating: 4.5,
      inStock: true,
    },
    {
      title: "Hair Growth Combo with Rx M ",
      price: 20,
      oldPrice: 25,
      imageSrc: ProdImg,
      rating: 4.5,
      inStock: false,
    },
  ];

  const sortOptions = [
    { label: "Frequently", value: "Frequently" },
    { label: "Recommended", value: "Recommended" },
    { label: "Featured", value: "Featured" },
    { label: "Popular", value: "Popular" },
    { label: "Latest", value: "Latest" },
  ];
  return (
    <>
      <section className="ptop-5 pbottom-5">
        <div className="side-space">
          <h3 className="heading-h2 mb-5 text-center">Today Deal</h3>
          <Row>
            {/* product List */}
            <Col xs lg="12" md="12">
              <div className="product-list">
                {product.map((product, index) => (
                  <ProductCard product={product} />
                ))}
              </div>

              
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default Deal;
