import React, { useState } from "react";
import "./video_card.css";
import Image from "react-bootstrap/Image";
import { RxCrossCircled } from "react-icons/rx";
import { FaPlay } from "react-icons/fa";
import "./video_card.css";

const VideoCard = ({ contents }) => {
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [currentContent, setCurrentContent] = useState({});

  const openLightbox = (content) => {
    setCurrentContent(content);
    setLightboxIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentContent({});
    setLightboxIsOpen(false);
  };

  return (
    <>
      {contents.map((content, index) => (
        <div className="media-card content-thumbnail position-relative " key={index}
          onClick={() => openLightbox(content)}
        >
          <div className="media-img event__thumb">
            <Image
              src={content.thumbnailUrl}
              alt="Content Thumbnail"
              width={500}
              height={400}
              style={{ width: "100%" }}
            />
          </div>

          <div className="play-icon">
            <FaPlay className="play" />
          </div>
        </div>
      ))}

      {lightboxIsOpen && (
        <div className="lightbox">
          <div className="lightbox-content">
            <div className="video-container">
              <iframe
                width="600"
                height="400"
                src={currentContent.videoUrl}
                title="YouTube Video"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
            <button onClick={closeLightbox}>
              <RxCrossCircled />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default VideoCard;
