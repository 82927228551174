import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";
import CustomButton from "../../../shared/CustomButton";

const HairClubForm = () => {
  return (
    <>
      <div className="hairclub-form">
        <Form className="form form-bg ">
          <Row className="mb-3">
            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                First Name <span className="text-danger">* </span>
              </Form.Label>
              <Form.Control type="tex" placeholder="Enter First Name" />
            </Form.Group>

            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                Last Name <span className="text-danger">* </span>
              </Form.Label>
              <Form.Control type="tex" placeholder="Enter Last Name" />
            </Form.Group>

            <Form.Group as={Col} md="12" className="mb-3">
              <Form.Label>
                Email <span className="text-danger">* </span>
              </Form.Label>
              <Form.Control type="email" placeholder="Enter Your  Email " />
            </Form.Group>

            <Form.Group as={Col} md="12" className="mb-3">
              <Form.Label>
                Mobile <span className="text-danger">* </span>
              </Form.Label>
              <Form.Control type="text" placeholder="Enter Mobile" />
            </Form.Group>

            <Form.Group as={Col} md="12" className="mb-3">
              <Form.Label>
                Mailing Address <span className="text-danger">* </span>
              </Form.Label>
              <Form.Control type="text" placeholder="Mailing Address" />
            </Form.Group>

            <Form.Group as={Col} md="12" className="mb-3">
              <Form.Label>
                {" "}
                Store/Salon's Physical Address{" "}
                <span className="text-danger">* </span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Store/Salon's Physical Address "
              />
            </Form.Group>

            <Form.Group as={Col} md="12" className="mb-3">
              <Form.Label>
                {" "}
                Title (Owner, Stylist, Owner & Stylist, Barber, Business Manager
                etc.) <span className="text-danger">* </span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Title (Owner, Stylist, Owner & Stylist, Barber, Business Manager etc.)"
              />
            </Form.Group>

            <Form.Group as={Col} md="12" className="mb-3">
              <Form.Label>
                {" "}
                Salon/Shop (Business) Name{" "}
                <span className="text-danger">* </span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Salon/Shop (Business) Name "
              />
            </Form.Group>

            <Form.Group as={Col} md="12" className="mb-3">
              <Form.Label>
                {" "}
                Salon/Shop (Business) Name{" "}
                <span className="text-danger">* </span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Salon/Shop (Business) Name "
              />
            </Form.Group>
            <Form.Group as={Col} md="12" className="mb-3">
              <Form.Label>
              Message 
              </Form.Label>
                <Form.Control as="textarea" placeholder="Message" />
            </Form.Group>

            <Form.Group as={Col} md="12" className="mb-3">
              <Form.Label>
                Security Code <span className="text-danger">* </span>
              </Form.Label>
              <InputGroup>
                <Form.Control placeholder="Enter Code → " />
                <InputGroup.Text id="basic-addon2"> 13456 </InputGroup.Text>
              </InputGroup>
            </Form.Group>

            <Col xs="12">
              <CustomButton
                outline={true}
                outlineColor="#3C3436"
                rounded={true} 
                color="#fff0"
                text="SUBMIT"
                textColor="#fff"
                hoverTextColor="#3C3436"
                backgroundColor="#C75E0A"
                hoverBackgroundColor="#fff"
              />
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default HairClubForm;
