import React from 'react'
import { Col, Row } from 'react-bootstrap'
import BlogCard from './component/BlogCard'
import BlogImage from "../../assets/blog.webp"

const Blog = () => {
    const blogs = [
        {
          title: 'Inside Your Gut - Unveiling Digestive Enzymes',
          category: 'Health & Wellbeing',
          date: '11 March, 2024',
          image: BlogImage,
          link: '/blog-details',
        },

        {
            title: 'Inside Your Gut - Unveiling Digestive Enzymes',
            category: 'Health & Wellbeing',
            date: '11 March, 2024',
            image: BlogImage,
            link: '/blog-details',
          },

          {
            title: 'Inside Your Gut - Unveiling Digestive Enzymes',
            category: 'Health & Wellbeing',
            date: '11 March, 2024',
            image: BlogImage,
            link: '/blog-details',
          },

          {
            title: 'Inside Your Gut - Unveiling Digestive Enzymes',
            category: 'Health & Wellbeing',
            date: '11 March, 2024',
            image: BlogImage,
            link: '/blog-details',
          },
       
      ];
  return (
    <>
      <section className='ptop-5 pbottom-5'>
          <div className='side-space'>
              <Row className='justify-content-center'>
               {blogs.map((blogs, index) => (
                 <Col xl="3" lg="4" md="6" sm="12" key={index}>
                     <BlogCard  blogData={blogs}/>
                 </Col>
                ))}
              </Row>
          </div>
      </section>
    </>
  )
}

export default Blog
