import React from "react";
import "./review.css";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CustomButton from "../../../shared/CustomButton";
import { Link } from 'react-router-dom';
import { FaStar } from "react-icons/fa";
import DummyImg from "../../../assets/dummy-img.jpg"

const Review = () => {
  return (
    <>
      <div className="review-container">
       <div className="mb-5">
        <h4>2 Reviews For Adult Daily Multivitamin Gummies - (30 Day Supply)
</h4>
        <ul className="comments-list">
        <li className="comment comment-has-children">
            <div className="comment-body">
                <div className="comment-author-avatar">
                    <Link to="#"><img src={DummyImg} alt="John Smith" /></Link>
                </div>
                <div className="comment-author-info">
                    <p className="fn mb-0">John Smith</p>
                    <div className="comment-meta mt-2 mb-2">
                        <p className="mb-0"><b>October 13, 2020</b> | </p>
                        
                            <div className="mad-rating-top-level" >
                                <FaStar className="rating-icon"/>
                                <FaStar className="rating-icon"/>
                                <FaStar className="rating-icon"/>
                                <FaStar className="rating-icon"/>
                                <FaStar className="rating-icon"/>
                            </div>
                    </div>
                    <div className="comment-content">
                        <p className="mb-0">Aliquam dapibus tincidunt metus. Praesent justo dolor, lobortis.</p>
                    </div>
                </div>
            </div>
        </li>

        <li className="comment comment-has-children">
            <div className="comment-body">
                <div className="comment-author-avatar">
                    <Link to="#"><img src={DummyImg} alt="John Smith" /></Link>
                </div>
                <div className="comment-author-info">
                    <p className="fn mb-0">John Smith</p>
                    <div className="comment-meta mt-2 mb-2">
                        <p className="mb-0"><b>October 13, 2020</b> | </p>
                        
                            <div className="mad-rating-top-level" >
                                <FaStar className="rating-icon"/>
                                <FaStar className="rating-icon"/>
                                <FaStar className="rating-icon"/>
                                <FaStar className="rating-icon"/>
                                <FaStar className="rating-icon"/>
                            </div>
                    </div>
                    <div className="comment-content">
                        <p className="mb-0">Aliquam dapibus tincidunt metus. Praesent justo dolor, lobortis.</p>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</div>
<hr/>
       <div>
        <h3 className="mb-3">Add a Review</h3>
        <Form action="#">
          <Row>
            <Col xl="4" lg="4" md="6">
              <div className="rate-box">
                <lable>Your Rating </lable>
                <div className="star-rating mb-3">
                  <input type="radio" id="5-stars" name="rating" value="5" />
                  <label for="5-stars" className="star">
                    ★
                  </label>
                  <input type="radio" id="4-stars" name="rating" value="4" />
                  <label for="4-stars" className="star">
                    ★
                  </label>
                  <input type="radio" id="3-stars" name="rating" value="3" />
                  <label for="3-stars" className="star">
                    ★
                  </label>
                  <input type="radio" id="2-stars" name="rating" value="2" />
                  <label for="2-stars" className="star">
                    ★
                  </label>
                  <input type="radio" id="1-star" name="rating" value="1" />
                  <label for="1-star" className="star">
                    ★
                  </label>
                </div>
              </div>
            </Col>

            <Col xl="4" lg="4" md="6">
              <Form.Group className="mb-3">
              <lable>Name<span className="text-danger">*</span> </lable>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Name"
                  required
                />
              </Form.Group>
            </Col>

            <Col xl="4" lg="4" md="12">
              <Form.Group className="mb-3">
              <lable>Email<span className="text-danger">*</span> </lable>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Email"
                  required
                />
              </Form.Group>
            </Col>

            <Col xl="12" md="12">
              <Form.Group className="mb-3">
              <lable> Your Review<span className="text-danger">*</span> </lable>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="review"
                  placeholder="Write your review"
                />
              </Form.Group>
            </Col>

            <div className="d-flex justify-content-center mt-3">
                <div style={{width:'200px'}}>
                <CustomButton
                    rounded={true} 
                    color="#fff0"
                    text="Add Review"
                    textColor="#fff"
                    hoverTextColor="#fff"
                    backgroundColor="#3C3436"
                    hoverBackgroundColor="#D46A15"
                />
                </div>
            </div>
          </Row>
        </Form>
        </div>
      </div>
    </>
  );
};

export default Review;
