import React from 'react';
import CustomLink from '../../shared/LinkCustom';

export default function CartProceedBtn() {
  return (
    <>
       <div className="cart-details-left">
          <h3>
            Subtotal (1 items) : <strong>$0.05</strong>
          </h3>
          <div className="continue-shopping-butotn mt-3 d-flex w-100">
           <CustomLink  border={false}
              borderWidth={0}
              rounded={true} 
              link="/checkout"
              color="#ffd814"
              text="Proceed To Buy"
              textColor="#000"
              hoverTextColor="#000"
              backgroundColor="#ffd814"
              hoverBackgroundColor="#ebb001"/>
          </div>
        </div>
    </>
  )
}
