import React from "react";
import CustomButton from "../../shared/CustomButton";
import CustomLink from "../../shared/LinkCustom";

import "./CheckoutAddress.css";

export default function CheckoutAddress() {
  return (
    <>
      <div className="mt-2">
        <div className="address-box p-3 m-2">
          <h3>Jyotirmoy Sinha</h3>
          <p>
            Dda Flat, Pocket 13, Dwarka Ph 1, Manglapuri, Palam New Delhi 110045
          </p>
          <p>
            <b>Country : </b> Bermuda
          </p>
          <p>
            <b>Phone : </b> 9999652812
          </p>

          <div className="mt-3">
            <div className="w-100 d-flex">
              <CustomLink
                border={false}
                borderWidth={0}
                rounded={true}
                link="/payment"
                color="#ffd814"
                text="Deliver to this address"
                textColor="#000"
                hoverTextColor="#000"
                backgroundColor="#ffd814"
                hoverBackgroundColor="#ffc107"
              />
            </div>
          </div>

          <div className=" d-flex gap-2 gap-sm-3 mt-3">
            <CustomButton
              border={false}
              borderWidth={0}
              rounded={true}
              color="#ffd814"
              text="Edit"
              textColor="#000"
              hoverTextColor="#000"
              backgroundColor="#CFD8DC"
              hoverBackgroundColor="#bbc6cc"
            />

            <CustomButton
              border={false}
              borderWidth={0}
              rounded={true}
              color="#ffd814"
              text="Remove"
              textColor="#000"
              hoverTextColor="#000"
              backgroundColor="#CFD8DC"
              hoverBackgroundColor="#bbc6cc"
            />
          </div>
        </div>
      </div>
    </>
  );
}
