import React, { useState, useEffect } from "react";
import "./header.css";
import SearchComponent from "./Search";
import Logo from "../../assets/Logo.svg";
import CartIcon from "../../assets/cart.svg";

import { Outlet, Link } from "react-router-dom";
import { FiUser } from "react-icons/fi";
import { ImLocation2 } from "react-icons/im";
import LinkCustom from "../../shared/LinkCustom";
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import SideMenu from "./SideMenu";


function NavComponent() {
  const [isAllCategoriesOpen, setAllCategoriesOpen] = useState(false);
  const [isProduct, setIsProduct] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isWorkWithUs, setIsWorkWithUs] = useState(false);

  const AllCategoriesMouseEnter = () => {
    setAllCategoriesOpen(true);
  };

  const AllCategoriesMouseLeave = () => {
    setAllCategoriesOpen(false);
  };

  const ProductMouseEnter = () => {
    setIsProduct(true);
  };

  const ProductMouseLeave = () => {
    setIsProduct(false);
  };

  const LoginMouseEnter = () => {
    setIsLoginOpen(true);
  };

  const LoginMouseLeave = () => {
    setIsLoginOpen(false);
  };

  const WorkWithUsMouseEnter = () => {
    setIsWorkWithUs(true);
  };

  const WorkWithUsMouseLeave = () => {
    setIsWorkWithUs(false);
  };

  // for scroll

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsSticky(scrollTop > 650);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <>
      <header className={isSticky ? "sticky" : ""}>
        <div className="navigation-wrap">

            {/* top part */}

            <div className="w-100 d-flex justify-content-center  py-2 justify-content-lg-between align-items-center side-space">
              <div className="d-none d-lg-block">
                <p className="mb-0 header-text">
                  <span className="light-brwon"> <b>Bonanza**</b> </span>
                    Advanced Skin Tag And Wart Removal Formula
                </p>
              </div>

              <div className="top-menu text-right  d-none d-lg-block">
                <ul className="list-menu">
                 

                  <li className="nav-item m-0 ">
                    <Link
                      className="nav-link  topmenu-padding"
                      to="tel: +1 (441)293-5476"
                    >
                     <b>  Help Line +1 (441)293-5476</b>
                    </Link>
                  </li>

                  <li className="nav-item m-0 ">
                    <Link className="nav-link topmenu-padding" to="/about-us">
                     About Us
                    </Link>
                  </li>

                  <li className="nav-item m-0 ">
                    <Link className="nav-link topmenu-padding" to="/contact-us">
                     Contact Us
                    </Link>
                  </li>

                  <li className="nav-item m-0 pe-0">
                    <Link className="nav-link  topmenu-padding pe-0" to="/track-order">
                     Track Order 
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="top-menu text-right d-block d-lg-none pb-1">
                <ul className="list-menu">
                   <li className="nav-item m-0 pe-sm-3"> <Link to="/hair-club" className="light-brown-btn">Join hair Club Member</Link> </li>
                   <li className="nav-item m-0 d-none d-sm-block">
                        <Link to="/hair-master-class" className="light-brown-btn text-capitalize">Hair Master Class</Link>
                    </li>

                    <li className="d-none d-sm-block nav-item m-0 show" onMouseEnter={WorkWithUsMouseEnter} onMouseLeave={WorkWithUsMouseLeave} >
                     <Link to="" className="light-brown-btn ms-3 show text-capitalize" aria-haspopup="true"  aria-expanded={isWorkWithUs} >Work with us  {isWorkWithUs ? <FaCaretUp className="ms-2"/> : <FaCaretDown className="ms-2"/>}
</Link>

                     {isWorkWithUs && (
                          <div  className="dropdown-menu dropdown-position"   onMouseEnter={WorkWithUsMouseEnter} onMouseLeave={WorkWithUsMouseLeave} >
                            <div className="mega-box-signin" style={{width:'200px'}}>
                             
                                <div className="">
                                  <ul className="inner-menu">
                                    <li>
                                      <Link className="dropdown-item" to="/login">
                                        Become an Agent
                                      </Link>
                                    </li>

                                    <li>
                                      <Link className="dropdown-item" to="#">
                                        Become Whole seller
                                      </Link>
                                    </li>

                                    <li>
                                      <Link className="dropdown-item" to="/affiliate-registration">
                                      Become an Affiliate
                                      </Link>
                                    </li>
                                  </ul>
                                </div>

                              </div>
                          </div>
                        )}
                    </li>
                 </ul>
              </div>
            </div>

            <div className="hr-line m-0"></div>
             {/* middle  part */}

            <div className="side-space">
            <nav className="navbar navbar-expand-md flex-nowrap align-items-center pl-0 pr-0 d-flex justify-content-between">
              <div className="me-3 ">
                <Link className="navbar-brand" to="/">
                  <img src={Logo} alt="shopnmac Logo" />
                </Link>
              </div>
              <div className="w-60-tablet width-100 d-none d-md-block">
                <div className="collapse navbar-collapse align-items-center"   id="collapsibleNavbar" >
                 
                  <div className="pe-3 d-none-tablet">
                    <ul className="navbar-nav ms-2 align-items-center">
                    <li className="nav-item mr-0 m-0 ">
                          <Link
                            className="nav-link d-flex"
                            to="tel:(441)293-5476"
                          >
                          <ImLocation2  className="map-icon me-2"/>

                            <span>
                              Deliver to DesignDot <br></br>
                              <b>New Delhi 110045‌</b>
                            </span>
                          </Link>
                        </li>
                    </ul>
                  </div>

                   {/* search */}
                  <div className="search w-60 w-tablet-100 mw-100 d-none d-md-block">
                    <SearchComponent></SearchComponent>
                  </div>
                  {/* end search */}

               
                </div>
              </div>

              <div className="w-70">
                {/* cart / login */}
                <div className="cart_login">
                    <ul className="d-flex align-items-center justify-content-end">
                      <li className="nav-item m-0 pe-3 d-none d-lg-block"> <Link to="/hair-club" className="light-brown-btn">Join hair Club Member</Link> </li>

                      <li className="nav-item show m-0 pe-2 pe-sm-3" onMouseEnter={LoginMouseEnter}  onMouseLeave={LoginMouseLeave} >
                        <Link className="gray-btn text-capitalize p-0 pe-sm-0" to="#">
                           <span className="px-3 d-none d-sm-block"> Login </span>
                           <FiUser className="login-user-icon" />
                        </Link>

                        {isLoginOpen && (
                          <div
                            className="dropdown-menu dropdown-position"
                            onMouseEnter={LoginMouseEnter}
                            onMouseLeave={LoginMouseLeave}
                          >
                            <div className="mega-box-signin">
                              <div className="login-btn mt-2 mb-2 text-center" style={{borderBottom: '1px solid #e9e9e9'}}>
                               
                                <LinkCustom
                                  outline={false}
                                  outlineColor="#ffff"
                                  radius={true}
                                  color="info"
                                  text="Sign In"
                                  link="/login"
                                  textColor="#fff"
                                  hoverTextColor="#fff"
                                  backgroundColor="#C75E0A"
                                  hoverBackgroundColor="#770A00"
                                />

                                <p className="fw-400 fa-13 mb-0 pt-3">
                                  New Customer ?
                                  <Link to="/login" className="text-capitalize ms-2" style={{color:'#C75E0A',fontSize:'14px'}}>
                                    <b>Start Here</b>
                                  </Link>
                                  
                                </p>
                              </div>

                              <div className="line"></div>

                              <div className="mega-box-1">
                                <div className="mega-menu-1">
                                  <p className="menu-title mb-1">
                                    Your Account
                                  </p>
                                  <ul className="inner-menu">
                                    <li>
                                      <Link className="dropdown-item" to="#">
                                         Account
                                      </Link>
                                    </li>

                                    <li>
                                      <Link className="dropdown-item" to="#">
                                       Orders
                                      </Link>
                                    </li>
                                    <li>
                                      <Link className="dropdown-item" to="/product">
                                       Wishlist
                                      </Link>
                                    </li>

                                    <li>
                                      <Link className="dropdown-item" to="#">
                                        Subscribe & Save Items
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </li>
                      <li className="nav-item m-0 pe-1">
                        <Link to="/cart" className="text-decoration-none">
                          <img
                            src={CartIcon}
                            className="cartimg"
                            alt="Cart Icon"
                          />
                          <span className="text-danger cart-no"><b>2</b></span>
                        </Link>
                       
                      </li>

                      <li className="nav-item m-0 d-none d-sm-block">
                        <Link className="cart-tot-text">
                           <b>Total</b>
                           <p className="cart-tot-value mb-0">$ 20</p>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
            </nav>
            </div>

            <div className="hr-line m-0"></div>

            {/* bottom part */}
            
            <div className="side-space">
              <div className="bottom-header w-100 display-flex align-items-center pt-2 pb-2">
                <div className="w-custom">
                  <ul className="catgory-list">

                    {/*start  sidemenu */}
                    <li className="nav-item">
                       <SideMenu/>
                    </li>

                      {/*end  sidemenu */}
                  </ul>
                </div>
                <div className="width-100 w-medium-80">

                  {/*search  for mobile */}

                  <div className="search w-60 w-tablet-100 mw-100 d-block d-md-none">
                    <SearchComponent></SearchComponent>
                  </div>

                   {/*search for mobile */}

                  <div className="category-menu d-none d-md-block">
                    <ul className="list-bottom-menu">
                    <li  className="nav-item m-0 show"  onMouseEnter={ProductMouseEnter} onMouseLeave={ProductMouseLeave} >
                        <Link className="nav-link topmenu-padding"  to="#" aria-haspopup="true"  aria-expanded={isProduct}  >
                           All Products  {isProduct ? <FaCaretUp className="ms-2"/> : <FaCaretDown className="ms-2"/>}
                        </Link>

                        {isProduct && (
                          <div
                            className="dropdown-menu "
                            onMouseEnter={ProductMouseEnter}
                            onMouseLeave={ProductMouseLeave}
                          >
                           
                                <ul className="inner-menu" style={{width: '200px'}}>
                                  <li>
                                    <Link className="dropdown-item" to="/shop">
                                    Hair Growth
                                    </Link>
                                   </li>
                                   <li>
                                    <Link className="dropdown-item" to="/shop">
                                     Skin Care
                                    </Link>
                                    </li>
                                    <li>
                                    <Link className="dropdown-item" to="/shop">
                                      Health & Wellbeing
                                    </Link>
                                  </li>
                                </ul>
                          </div>
                        )}
                      </li>

                      <li className="nav-item m-0 ">
                        <Link className="nav-link topmenu-padding" to="/clinical-results
">
                        Clinical Results
                        </Link>
                      </li>

                      <li className="nav-item m-0 ">
                        <Link className="nav-link topmenu-padding" to="/testimonial">
                        Testimonial
                        </Link>
                      </li>

                      <li className="nav-item m-0 ">
                        <Link className="nav-link topmenu-padding" to="/find-store">
                        Find Store
                        </Link>
                      </li>

                      <li className="nav-item m-0 ">
                        <Link className="nav-link topmenu-padding" to="/deal">
                        Deals
                        </Link>
                      </li>

                      <li className="nav-item m-0 ">
                        <Link className="nav-link topmenu-padding" to="">
                        Buy Again
                        </Link>
                      </li>

                    </ul>
                  </div>
                </div>

                <div className="cart_login w-40 d-none d-lg-block">
                  <ul className="ms-2 d-flex align-items-center justify-content-end">
                    <li>
                        <Link to="/hair-master-class" className="light-brown-btn text-capitalize">Hair Master Class</Link>
                    </li>

                    <li className="nav-item m-0 show" onMouseEnter={WorkWithUsMouseEnter} onMouseLeave={WorkWithUsMouseLeave} >
                     <Link to="" className="light-brown-btn ms-3 show text-capitalize" aria-haspopup="true"  aria-expanded={isWorkWithUs} >Work with us  {isWorkWithUs ? <FaCaretUp className="ms-2"/> : <FaCaretDown className="ms-2"/>}
</Link>

                     {isWorkWithUs && (
                          <div  className="dropdown-menu dropdown-position"   onMouseEnter={WorkWithUsMouseEnter} onMouseLeave={WorkWithUsMouseLeave} >
                            <div className="mega-box-signin" style={{width:'200px'}}>
                             
                                <div className="">
                                  <ul className="inner-menu">
                                    <li>
                                      <Link className="dropdown-item" to="/login">
                                        Become an Agent
                                      </Link>
                                    </li>

                                    <li>
                                      <Link className="dropdown-item" to="">
                                        Become Whole seller
                                      </Link>
                                    </li>

                                    <li>
                                      <Link className="dropdown-item" to="/affiliate-registration">
                                      Become an Affiliate
                                      </Link>
                                    </li>
                                  </ul>
                                </div>

                              </div>
                          </div>
                        )}
                    </li>
                  </ul>
                 
                 
                </div>
              </div>
            </div>
        </div>
      </header>
      <Outlet />
    </>
  );
}

export default NavComponent;
