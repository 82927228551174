import { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { MdOutlineShoppingBag } from "react-icons/md";
import "./cartPopup.css";
import { FaRegTrashCan } from "react-icons/fa6";
import Quantity from "../webpages/ProductDetail/component/Quantity";
import LinkCustom from "./LinkCustom";
import CustomButton from "./CustomButton";
import CouponCard from "./CouponCard";
import ProdImg from "../assets/product/prod-1.png";
import {Link} from "react-router-dom"

const OffCanvasExample = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button className="prod_cart border-0" onClick={handleShow}>
        <MdOutlineShoppingBag />
      </button>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            Your cart <span className="badge bg-danger fw-medium">5</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <hr className="my-0" />
        <Offcanvas.Body>
          <div className="sidebar-cart">
            <p>
              WOW! Take Extra 10% Off on this order. <br></br>
              Save more! Shop for ₹15000 & above. Get <b>Extra 15% Off</b>{" "}
            </p>
          </div>

          {/* start Coupon */}

          <CouponCard />

          {/*end  Coupon */}

          {/* start cart */}

          <div className="sidebar-cart-itme mt-4">
            <div className="cart-body">
              <div className="mini-cart-item">
                <div className="mini-cart-image">
                  <Link to="#">
                    <img loading="lazy" src={ProdImg} alt="product name" />
                  </Link>
                </div>
                <div className="mini-cart-details">
                  <Link to="#" className="mini-cart-title line-clamp-1">
                    Adult Daily Multivitamin Gummies
                  </Link>
                  <div className="cart-variable">
                    <p>
                      <strong>Categories : </strong>
                      Health & Wellness
                    </p>
                  </div>
                  <div className="mini-cart-qty">
                    <Quantity />
                  </div>

                  <div className="pvarprice d-flex align-items-center justify-content-between">
                    <p className="price mb-1">$25.00</p>

                    <div>
                      <FaRegTrashCan className="text-danger fa-18x" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mini-cart-item">
                <div className="mini-cart-image">
                  <Link to="#">
                    <img loading="lazy" src={ProdImg} alt="product name" />
                  </Link>
                </div>
                <div className="mini-cart-details">
                  <Link to="#" className="mini-cart-title line-clamp-1">
                    Adult Daily Multivitamin Gummies
                  </Link>
                  <div className="cart-variable">
                    <p>
                      <strong>Categories : </strong>
                      Health & Wellness
                    </p>
                  </div>
                  <div className="mini-cart-qty">
                    <Quantity />
                  </div>

                  <div className="pvarprice d-flex align-items-center justify-content-between">
                    <p className="price mb-1">$25.00</p>

                    <div>
                      <FaRegTrashCan className="text-danger fa-18x" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mini-cart-item">
                <div className="mini-cart-image">
                  <Link to="#">
                    <img loading="lazy" src={ProdImg} alt="product name" />
                  </Link>
                </div>
                <div className="mini-cart-details">
                  <Link to="#" className="mini-cart-title line-clamp-1">
                    Adult Daily Multivitamin Gummies
                  </Link>
                  <div className="cart-variable">
                    <p>
                      <strong>Categories : </strong>
                      Health & Wellness
                    </p>
                  </div>
                  <div className="mini-cart-qty">
                    <Quantity />
                  </div>

                  <div className="pvarprice d-flex align-items-center justify-content-between">
                    <p className="price mb-1">$25.00</p>

                    <div>
                      <FaRegTrashCan className="text-danger fa-18x" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* end cart */}

          <div className="cart-payment">
            <LinkCustom
              border={true}
              borderWidth={3}
              rounded={true}
              borderColor="#3c3436"
              link="cart"
              text="View Cart"
              textColor="#fff"
              hoverTextColor="#3C3436"
              backgroundColor="#3C3436"
              hoverBackgroundColor="#fff"
            />

            <CustomButton
              border={false}
              borderWidth={0}
              rounded={true}
              color="#ffd814"
              text="Checkout"
              textColor="#000"
              hoverTextColor="#000"
              backgroundColor="#ffd814"
              hoverBackgroundColor="#ffc107"
            />
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default OffCanvasExample;
