import React from 'react'
import { Col, Image, Row } from 'react-bootstrap';
import RecentBlogs from './component/SideBlog';
import "./blog_details.css";
import BlogImage from "../../assets/blog.webp"

const BlogDetails = () => {
    const recentBlogs = [
        {
          title: 'Inside Your Gut - Unveiling Digestive Enzymes',
          image:
            BlogImage,
        },
      
        {
            title: 'Inside Your Gut - Unveiling Digestive Enzymes',
            image:
              BlogImage,
          },
          {
            title: 'Inside Your Gut - Unveiling Digestive Enzymes',
            image:
              BlogImage,
          },
          {
            title: 'Inside Your Gut - Unveiling Digestive Enzymes',
            image:
              BlogImage,
          },
      ];
  return (
    <>
      <section className="ptop-5 pbottom-5">
        <div className="side-space">
            <Row>
                <Col xxl="9" xl="8" lg="8"  sm="12">
                    <div className="blog-details-page">
                        <p className="blog-date">March 23, 2022</p>
                        <h3 className="blog-heading">
                            Hair Restoration Response to Will &amp; Jada Smith &amp; Chris Rock Award Show Drama
                        </h3>

                        <div className="blog-img">
                            <Image  src={BlogImage} fluid alt="blog"/>
                        </div>

                        <div className="bog-description">
                            <p></p>
                            <p>
                                Northshore Medical Center is highlighting Salon B and its hair restoration offerings, including the treatment of alopecia.<br />
                                A spokesperson said, “We can learn many lessons from this weekend’s award events with actors Will and Jada Smith and comedian Chris Rock reactions, but for us at Northshore Medical Center. we have partnered with
                                Dr. Brown’s Laboratories to ensure Bermuda residents and visitors know that we have a full Hair &amp; Scalp Clinic with hair restoration product line on-island to support and treat your alopecia and other hair
                                loss conditions. There is no need to have alopecia effect your life and for you to suffer in silence.
                            </p>

                            <p>
                                “Salon B’s award-winning hair restoration team have been successfully servicing hundreds of Bermuda residents with their hair loss conditions and the results have been amazing. Men and women, youth and seniors
                                are all treated at Salon B. Starting with their hair consultations to their laser cap treatments, supplementation to other methods, they are here to support your hair restoration goals.
                            </p>

                            <p>
                                “There are many causes of alopecia, ranging from family history [heredity], aging, hormonal changes, medical conditions, medications, treatments, radiation therapy, lots of stress, and some hairstyles. For men
                                and women, there are many treatments and products available at Northshore Medical &amp; Aesthetics Center’s Salon B Hair &amp; Scalp Clinic.”
                            </p>

                            <p>
                                Dr. Kyjuan Brown, award-winning hair restoration specialist and formulator of Dr. Brown’s Laboratory, which also has a full hair restoration product line said, “We would like to be sure Bermuda knows that if they
                                need help with their alopecia hair challenges, we have a full hair and scalp clinic on island that can help treat your condition. My professional team are on island to help you daily and we have hundreds of
                                successful clients that we have treated successfully. Our results speak for themselves. Let’s reverse your hair loss. Don’t suffer in silence or think that your alopecia can not be treated.”
                            </p>
                            <p></p>
                        </div>
                    </div>
                </Col>

                <Col xxl="3" xl="4"  lg="4"   sm="12">
                    <div className='blog-details-page'>
                       <h3 className="common-heading mb-3">
    	                   RECENT POST
    	               </h3>
                        {recentBlogs.map((blog, index) => (
                                <RecentBlogs key={index} blog={blog} />
                            ))}
                     </div>
                </Col>
            </Row>
        </div>
    </section>

    </>
  )
}

export default BlogDetails
