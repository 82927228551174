import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import "./banner_info.css";
// import InfoCard from './component/InfoCard';
// import VeganIcon from  "../../../assets/icon/VEGAN.png"
// import HerbalIcon from  "../../../assets/icon/HERBAL.png"
// import NoSideEffectsIcon from  "../../../assets/icon/NO-SIDE-EFFECTS.png"
// import RecommendedaIcon from  "../../../assets/icon/RECOMMENDED.png"
// import TestedIcon from  "../../../assets/icon/TESTED.png"
import HairFallImg from  "../../../assets/hairfall-plan.png"



const Banner = () => {
  return (
    <>
      <section className='banner-info-bg ptop-5 pbottom-5'>
        <div className='side-space'>
           <Row>
               <Col xl="12" lg="12">
                   <div className='banner-info'>
                      <h1>Dr. Browns hoslistic plan for hair fall </h1>
                       <h3>Ayurveda + Dermatology + Nutrition</h3>
                       <p className='mt-4 para-width'>The best aspects of three sciences are combined in our method. Using chemicals 
                        sourced from the most reliable sources, we customize 
                        your treatment to yield guaranteed outcomes.</p>

                        {/* <div className='mt-5 icon-list'>
                            <InfoCard title="Vegan Freindly" ImgUrl={VeganIcon}/>
                            <InfoCard title="100% Herbal" ImgUrl={HerbalIcon}/>
                            <InfoCard title="No Side Effects" ImgUrl={NoSideEffectsIcon}/>
                            <InfoCard title="Doctor Recommendeda" ImgUrl={RecommendedaIcon}/>
                            <InfoCard title="Dermatology Tested" ImgUrl={TestedIcon}/>
                        </div> */}

                        <div className='mt-5'>
                           <Image src={HairFallImg} alt="banner" fluid/>
                        </div>
                   </div>
               </Col>
           </Row>
        </div>
      </section>
    </>
  )
}

export default Banner
