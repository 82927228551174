import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./product_tab.css";
import Review from "./Review";

function ProductTab() {
  return (
    <Tabs
      defaultActiveKey="review"
      id="fill-tab-example"
      className="mb-3"
      justify
    >
      <Tab eventKey="description" title="Description">
        <div className="tab-content">
          <h3> Hair Growth Nutraceutical</h3>
          <p>
            Our daily hair growth supplement is made for women 18-44 who are
            experiencing signs of hair thinning. Physician-formulated with
            natural ingredients to target root causes of thinning such as
            stress, lifestyle and nutrition, our award-winning formula results
            in faster-growing, thicker, fuller, stronger hair. And it’s an
            effortless addition to your daily wellness routine: 4 pills, 1 easy
            step, all the results.1-2
          </p>
        </div>
      </Tab>
      <Tab eventKey="additional_information" title="Additional information">
        <div className="tab-content">
          <h3> Recommended usage:</h3>
          <p>
            Once daily, and with a meal, take 4 capsules of your
            Nutraceutical—an efficacious dosage that contributes to visible
            results in 3-6 months. Tear open the refill pouch and pour the
            contents into the reusable jar once empty.
          </p>

          <h3>Benefits</h3>
          <ul>
            <li>Visibly thicker volume2</li>
            <li>Visibly thicker volume2</li>
            <li>Visibly thicker volume2</li>
            <li>Visibly thicker volume2</li>
            <li>Visibly thicker volume2</li>
          </ul>
        </div>
      </Tab>
      <Tab eventKey="review" title=" Reviews (2)">
        <div className="tab-content">
            <Review/>
        </div>
      </Tab>
    </Tabs>
  );
}

export default ProductTab;
