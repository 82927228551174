import React, { useState } from 'react';
import InputRange from 'react-input-range';
import LinkCustom from '../../../shared/LinkCustom';
import './range_filter.css';

function RangeExample() {
  const [values, setValues] = useState({ min: 0, max: 100 });

  const handleChange = (value) => {
    setValues(value);
  };

  return (
    <>
     <div className='range-box'>
        <h3 className='filter-heading mb-3'>Price Range</h3>
          <InputRange
            minValue={0}
            maxValue={100}
            value={values}
            onChange={handleChange}
            step={1}
          />
        <div className='range-text mt-2'> 
          <p>Price : ${values.min} - ${values.max}</p>
        </div>

        <div className='mt-4 pb-2'>
         <LinkCustom
            outline={false}
            outlineColor="#fff"
            radius={true}
            color="info"
            text="Filter"
            link="#"
            textColor="#fff"
            hoverTextColor="#fff"
            backgroundColor="#C75E0A"
            hoverBackgroundColor="#770A00"
          />
        </div>
     </div>
    </>
  );
}

export default RangeExample;
