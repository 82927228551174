import React from "react";
import { Link } from "react-router-dom";
import CheckoutForm from "./CheckoutForm";
import CheckoutAddress from "./CheckoutAddress";
import PickUpPoint from "./PickUpPoint";
import "./Checkout.css";

export default function Checkout() {
  return (
    <section className="ptop-5 pbottom-5">
      <div className="side-space">
        <div className="checkout-wrapper ptb-5">
          <div className="text-center">
            <h3 className=" heading-h1">Checkout Process</h3>
          </div>

          <div className="checkout-text">
            <h3 className="common-heading">Select A Delivery Address </h3>
            <p>
              Is the address you'd like to use displayed below? If so, click the
              corresponding "Deliver to this address" button. Or you can{" "}
              <Link to="#" className="ancher-text">
                <b>enter a new delivery address</b>
              </Link>
              .
            </p>
            <p className="mt-2">
              <b>On the move? Pick up your order from our pickup store.</b>
            </p>
          </div>

          <div className="checkout-content mt-5">
            {/* CheckoutForm  */}

            <div className="checkout-form-wrapper">
              <CheckoutForm></CheckoutForm>
            </div>

            {/* End CheckoutForm */}

            {/* CheckoutAddress */}

            <div className="checkout-address-wrapper">
              <div className="checkout-address">
                <CheckoutAddress></CheckoutAddress>
                <CheckoutAddress></CheckoutAddress>
                <CheckoutAddress></CheckoutAddress>
                <CheckoutAddress></CheckoutAddress>
              </div>

              <hr className="mx-2"></hr>

              {/* PickUpPoint */}
              <div className="pickup-point mx-2">
                <PickUpPoint></PickUpPoint>
              </div>
              {/* End PickUpPoint */}
            </div>

            {/* End CheckoutAddress */}
          </div>
        </div>
      </div>
    </section>
  );
}
