import React from 'react'
import { Image } from 'react-bootstrap';

const InfoCard = ({ImgUrl, title}) => {
  return (
    <>
      <div className='info_card'>
          <div className='info_icon'>
              <Image src={ImgUrl} alt="icon"/>
          </div>
          <p className='mt-2 mb-0'>{title}</p>
      </div>
    </>
  )
}

export default InfoCard
