import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./sidebar.css";
import {  FaAngleRight, FaBars } from "react-icons/fa";
import UserImg from "../../assets/user.png";
import { BiArrowBack } from "react-icons/bi";
import { Image } from "react-bootstrap";

const subMenus = {
  products: [
    { title: "Hair Growth", link: "/shop" },
    { title: "Skin Care", link: "/shop" },
    { title: "Health & Wellbeing", link: "/shop" },
  ],
  workwithus: [
    { title: "Become an Agent", link: "/login" },
    { title: "Become Whole seller", link: "/#" },
    { title: "Become an Affiliate", link: "/affiliate-registration" },
  ],
};

function SideMenu() {
  const [isNavOpen, setNavOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);

  const openNav = () => {
    setNavOpen(true);
  };

  const closeNav = () => {
    setNavOpen(false);
    setActiveMenu(null);
  };

  const openSubMenu = (menu) => {
    setActiveMenu(menu);
  };

  const backToMain = () => {
    setActiveMenu(null);
  };

  return (
    <>
      <section>
        <div className="menu">
          <Link to="#" onClick={openNav} className="sidebar-link d-flex align-items-center">
            <span className="bar-icon">
              <FaBars className="fa-bars" />
            </span>
           <span className="d-none d-sm-block"> All Menu</span>
          </Link>
        </div>
        <div>
          <div
            id="myNav"
            className={`overlay-navbar ${isNavOpen ? "open" : ""}`}
          >
            <div id="hmenu-customer-profile">
              <div className="profile-icon ">
                <Image src={UserImg} alt="user image" />
              </div>
              <div id="hmenu-customer-profile-right">
                <div id="hmenu-customer-name">Hello, Shakti</div>
              </div>
            </div>
            <div>
              <Link className="closebtn" onClick={closeNav} to="#">
                &times;
              </Link>
            </div>

            <div className="overlay-navbar-content overlay-navbar-content1">
              {activeMenu ? (
                <div className="submenu-slide">
                  <div>
                    <button  className="back-btn" onClick={backToMain} to="#">
                      <BiArrowBack  className="fa-20" />
                        Main Menu
                    </button>
                  </div>
                  <ul>
                    {subMenus[activeMenu].map((item, index) => (
                      <li key={index}>
                        <Link to={item.link} onClick={closeNav}>
                          {item.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <ul>
                  <li>
                    <div className="d-flex justify-content-between align-items-center">
                      <Link to="#" onClick={() => openSubMenu("products")}>
                        Products  <FaAngleRight className="fa-20" />
                      </Link>
                    </div>
                  </li>

                <li>
                  <Link to="/clinical-results" onClick={closeNav}>
                    Clinical Results
                  </Link>
                </li>

                <li>
                  <Link to="/find-store" onClick={closeNav}>
                    Find Store
                  </Link>
                </li>

                <li>
                  <Link to="/deal" onClick={closeNav}>
                    Deals 
                  </Link>
                </li>

                <li>
                  <Link to="/" onClick={closeNav}>
                    Buy Again
                  </Link>
                </li>


                <li>
                  <Link to="/about-us" onClick={closeNav}>
                    About Us
                  </Link>
                </li>

                <li>
                  <Link to="/testimoinal" onClick={closeNav}>
                    Testimonials
                  </Link>
                </li>

                <li>
                  <Link to="/blog" onClick={closeNav}>
                    Blog
                  </Link>
                </li>

                <li>
                  <Link to="/track-order" onClick={closeNav}>
                    Track Order
                  </Link>
                </li>

                <li>
                  <Link to="/contact-us" onClick={closeNav}>
                    Contact Us
                  </Link>
                </li>
                <li className="d-block d-sm-none">
                  <Link to="/hair-master-class"  onClick={closeNav}>
                  Hair Master Class
                  </Link>
                </li>
                 <li className="d-block d-sm-none">
                    <div className="d-flex justify-content-between align-items-center">
                      <Link to="#" onClick={() => openSubMenu("workwithus")}>
                        Work with us   <FaAngleRight className="fa-20" />
                      </Link>
                    </div>
                  </li>

                <p className="menu-title mb-sm-1 mt-3" onClick={closeNav}>
                  HELP & SUPPORT
                </p>

                <li>
                  <Link to="/faq" onClick={closeNav}>
                    FAQ's
                  </Link>
                </li>

                <li>
                  <Link to="#" onClick={closeNav}>
                    Help Line +1 (441)293-5476
                  </Link>
                </li>

                </ul>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SideMenu;
