import React, { useState } from 'react';
import {Form } from 'react-bootstrap';
import CustomButton from '../../shared/CustomButton';
import './trackorder.css'; 
import TrackStatus from './TrackStatus';

const TrackOrder = () => {
  const [trackingId, setTrackingId] = useState('');
  const [orderDetails, setOrderDetails] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const TrackData = {
        trackingId: trackingId,
      };
      setOrderDetails(TrackData); 
    } catch (error) {
      console.error('Error fetching order details:', error);
    }
  };

  return (
    <>
      <section className='ptop-5 pbottom-5'>
        <div className='side-space'>
          <h3 className='common-headig mb-4 text-center'>Tracking Id</h3>
          <div className='trackig-order'>
            <Form className='mt-3' onSubmit={handleSubmit}>
              <div className='trackorder-row'>
                <div className='w-100'>
                  <Form.Control
                    type="text"
                    placeholder="Enter Tracking Id"
                    value={trackingId}
                    onChange={(e) => setTrackingId(e.target.value)}
                    required
                    className='w-100'
                  />
                </div>
                <div className='tracker-width'>
                  <CustomButton
                    border={false}
                    borderWidth={0}
                    rounded={true} 
                    color="#ffd814"
                    text="Submit"
                    textColor="#fff"
                    hoverTextColor="#fff"
                    backgroundColor="#3C3436"
                    hoverBackgroundColor="#C75E0A"
                  />
                </div>
              </div>
            </Form>

          {/* Display order details if available */}
            {orderDetails && (
            <div className="mt-4 order-details">
              <h3 className='common-heading'>Order Details</h3>
              <p><b>Tracking ID:</b> 123456 </p>
              <p><b>Product : </b> Hair Growth Combo with Rx M </p>
              <p><b>Date:</b> 05/05/2024</p>
              <p><b>Shipping Status:</b> Processing</p>
              <TrackStatus/>
             </div>
          )}
          </div>
         
        </div>
      </section>
    </>
  );
};

export default TrackOrder;
