import React from 'react';
import './Contactus.css';
import {Link } from 'react-router-dom';
import ContactImage from  '../../assets/Contact-us.jpg';
import { Form, Image, InputGroup } from 'react-bootstrap';
import CustomButton from '../../shared/CustomButton';
import { MdCall } from "react-icons/md";
import { IoMailUnreadOutline } from "react-icons/io5";
import { MdOutlineChat } from "react-icons/md";
import { RiMapPin5Line } from "react-icons/ri";



export default function Contactus() {
  return (
    <>
        <section className='ptop-5 pbottom-5'>
            <div className='side-space'>
                <div className="header-contact-body ptb-5">
                    <div className="header-contact-form">
                        <div className='pb-4'>
                            <h3 className='common-heading'>Do you encounter any problems purchasing your goods?</h3>
                            <p>Email us at <Link className='ancher-text' to="mailto:info@shopnmac.com">info@shopnmac.com</Link> or fill in the form below. We'll get back to you within 24 hours (typically sooner!) to arrange a time to speak.</p>
                        </div>
                        <div className="form-body">
                            <div className="form-inner">
                            <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter Name" />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="Enter Email" />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control type="text" placeholder="Enter Phone" />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Message</Form.Label>
                                <Form.Control type="text" placeholder="Message" />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Security Code</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                    placeholder="Enter Code"
                                    />
                                    <InputGroup.Text id="basic-addon2">1234</InputGroup.Text>
                                </InputGroup>
                           </Form.Group>

                            <div className='mt-3'>
                                <CustomButton
                                    outline={true}
                                    outlineColor="#3C3436"
                                    rounded={true} 
                                    color="#fff0"
                                    text="Send Message"
                                    textColor="#fff"
                                    hoverTextColor="#000"
                                    backgroundColor="#C75E0A"
                                    hoverBackgroundColor="#CFD8DC"
                                />
                            </div>

                            </Form>
                            
                            </div>
                        </div>
                    </div>
    
                    <div className="header-contact-information mt-3 mt-lg-0">
                        <div className="information-cells">
                            <div className="information-cell">
                                <div className="information-cell__icon">
                                    <span className="icon">
                                       <MdCall />
                                    </span>
                                </div>
            
                                <div className="information-cell__content">
                                    <div className="information-cell__title">Call us</div>
                                    <Link to="tel:01225580037" className="information-cell__link">1(441)293-5476</Link>
                                </div>
                            </div>
            
                            <div className="information-cell ">
                                <div className="information-cell__icon">
                                    <span className="icon">
                                       <IoMailUnreadOutline />
                                    </span>
                                </div>
            
                                <div className="information-cell__content">
                                    <div className="information-cell__title">Email us</div>
                                    <Link to="mailto:info@blubolt.com" className="information-cell__link">
                                      info@drbrownslab.com
                                    </Link>
                                </div>
                            </div>
            
                            <div className="information-cell">
                                <div className="information-cell__icon">
                                    <span className="icon">
                                       <MdOutlineChat />
                                    </span>
                                </div>
            
                                <div className="information-cell__content">
                                    <div className="information-cell__title">Chat to us</div>
                                    <Link to="#" className="information-cell__link">
                                        Click the message icon on <br></br>
                                        any page to live chat!
                                    </Link>
                                </div>
                            </div>
            
                            <div className="information-cell">
                                <div className="information-cell__icon">
                                    <span className="icon">
                                       <RiMapPin5Line />
                                    </span>
                                </div>
            
                                <div className="information-cell__content">
                                    <div className="information-cell__title">Visit us</div>
                                    <Link to="#" className="information-cell__link">
                                    7 North Shore Road, Devonshire Dv01, <br></br>Bermuda, Next to Empire Grocery
                                    </Link>
                                </div>
                            </div>
            
                            <div className="information-cell information-cell--wide">
                                <Image src={ContactImage} fluid alt="contact" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
               
    </>
  )
}
