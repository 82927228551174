import React, { useState, useEffect } from 'react';
import './trackorder.css';

const TrackStatus = () => {
  const [orderStatus, setOrderStatus] = useState('Shipped');
  const [previousStatus, setPreviousStatus] = useState(null);

  const statuses = [
    { name: 'Pending', color: 'green' },
    { name: 'Processing', color: 'green' },
    { name: 'Shipped', color: 'green' }, 
    { name: 'Delivered', color: 'green' }
  ];

  useEffect(() => {
    setPreviousStatus(orderStatus);
  }, [orderStatus]);

  const getColor = (statusName) => {
    const currentStatusIndex = statuses.findIndex(status => status.name === statusName);
    if (currentStatusIndex !== -1) {
      const currentStatus = statuses[currentStatusIndex];
      if (previousStatus && previousStatus !== currentStatus.name) {
        return 'gray';
      } else {
        return currentStatus.color;
      }
    }
    return 'green'; 
  };

  return (
    <div className="track-order-container">
      <div className="status-bar">
        {statuses.map(status => (
          <div
            key={status.name}
            className="status-item"
          >
            <div
              className={`status-circle ${orderStatus === status.name ? 'current-status' : ''}`}
              style={{ backgroundColor: getColor(status.name) }}
            ></div>
            <span className="status-name">{status.name}</span>
          </div>
        ))}
        <div className="status-line"></div>
      </div>
    </div>
  );
};

export default TrackStatus;
