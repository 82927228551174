import React from 'react'
import { Col, Row, Table } from 'react-bootstrap';
import AffiliateRegistrationForm from "./component/AffiliateRegistrationForm";
import Accordion from 'react-bootstrap/Accordion';
import "./affiliate_registration.css";
import { Link } from 'react-router-dom';

const AffiliateRegistration = () => {


  return (
    <>
       <section className="ptop-5 pbottom-5">
         <div className='side-space'>
            <Row>
                <Col xl="8" lg="7" md="12" xs="12">
                     <AffiliateRegistrationForm/>
                    </Col>
                    <Col xl="4" lg="5" md="12" xs="12">
                    <div className='mt-3 mt-lg-0'>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Commission & Business Details</Accordion.Header>
                                <Accordion.Body>
                                    <Table bordered  border="1">
                                            <tbody>
                                                <tr>
                                                    <th width="150">Conversion Action</th>
                                                    <td>Online purchase with processed valid payment</td>
                                                </tr>
                                                <tr>
                                                    <th>Cookie Days</th>
                                                    <td>
                                                        30 days
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Commission Type</th>
                                                    <td>
                                                        Percent of Sale
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Base Commission</th>
                                                    <td>
                                                        15%
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>  

                                         <div>
                                <p className='mb-1'> Call us with Questions at 1(441)293-5476 </p>
                                <p className='mb-1'><b>Social Media : </b> </p>
                                <Link to="https://www.instagram.com/drbrownslaboratory/" target="_blank">https://www.instagram.com/drbrownslaboratory/</Link><br/>
                                <Link to="https://www.facebook.com/drbrownslaboratory" target="_blank">https://www.facebook.com/drbrownslaboratory</Link><br/>
                                <Link to="https://www.youtube.com/channel/UCrVOWQ49v-q-iBcaoNT-4pg" target="_blank">https://www.youtube.com/channel/UCrVOWQ49v-q-iBcaoNT-4pg</Link><br/>
                               
                            </div>  
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                           
                        </div>
                </Col>
            </Row>

         </div>
      
        
      </section>
    </>
  )
}

export default AffiliateRegistration
