import React from 'react';
import "./text_banner.css";
import {Image} from 'react-bootstrap';
import BannerImg from "../../../assets/Banner.png"
import InfoCard from '../Banner/component/InfoCard';
import VeganIcon from  "../../../assets/icon/VEGAN.png"
import HerbalIcon from  "../../../assets/icon/HERBAL.png"
import NoSideEffectsIcon from  "../../../assets/icon/NO-SIDE-EFFECTS.png"
import RecommendedaIcon from  "../../../assets/icon/RECOMMENDED.png"
import TestedIcon from  "../../../assets/icon/TESTED.png"

const TextBanner = () => {
  return (
    <section className='position-relative'>
      
      <div className='w-100'>
       <div className='text-bg-image'>
         <Image src={BannerImg}  alt="banner" fluid/>
       </div>
         <div className='icon-list'>
            <InfoCard title="Vegan Freindly" ImgUrl={VeganIcon}/>
            <InfoCard title="100% Herbal" ImgUrl={HerbalIcon}/>
            <InfoCard title="No Side Effects" ImgUrl={NoSideEffectsIcon}/>
            <InfoCard title="Doctor Recommended" ImgUrl={RecommendedaIcon}/>
            <InfoCard title="Dermatology Tested" ImgUrl={TestedIcon}/>
           </div>
      </div>
      {/* <Container>
        <Row className="align-items-center"> */}
          {/* <Col xl={5} className="left-content" data-aos="fade-up" data-aos-delay="100">
            <h3>The First and Only Nutraceutical Brand Accepted by the American Hair Loss Association.</h3>
            <p>
              The American Hair Loss Association is the oldest national nonprofit consumer advocacy group dedicated to educating the public about the emotionally devastating condition of alopecia (hair loss). For over 20 years, the
              AHLA has supported ongoing research and been dedicated to increasing awareness in the medical community. The AHLA is the industry’s leading consumer advocacy group and the most trusted name in hair loss. Advanced
              Trichology is proud to carry the AHLA seal, widely considered the gold standard in the hair loss industry.
            </p>
          </Col>

          <Col xl={1} style={{ width: '15%', maxWidth: '15%' }} data-aos="fade-up" data-aos-delay="100"></Col>

          <Col xl={5} data-aos="fade-up" data-aos-delay="100">
            <img src="//www.advancedtrichology.com/cdn/shop/t/70/assets/ahla-seal.svg?v=20515683296803358011670074576" alt="" />
            <div className="accp-box">
              <p>
                “Advanced Trichology has a proven commitment to in-clinic research, customer care and quality. Advanced Trichology is the first dietary <strong>supplement</strong> brand to be awarded the American Hair Loss
                Association’s Trusted Brand status.”
              </p>
            </div>
          </Col> */}

          {/* <Col xl="12"> */}
          {/* </Col>
        </Row>
      </Container> */}

     
    </section>
  );
};

export default TextBanner;
