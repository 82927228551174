import React from 'react';
import { Swiper, SwiperSlide  } from 'swiper/react';
import { Navigation} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { IoIosArrowBack , IoIosArrowForward  } from "react-icons/io";
import Image from 'react-bootstrap/Image';
import SliderImg from "../../../assets/slider/Slider.jpg"

const Slider = () => {
      return (
        <>
            <Swiper
            modules={[Navigation]}
            spaceBetween={50}
            slidesPerView={1}
            navigation={{
                prevEl: '.swiper-silder-button-prev',
                nextEl: '.swiper-silder-button-next',
            }}
            >
                       
              <SwiperSlide >
                  <Image src={SliderImg} className="img-fluid w-100" />
              </SwiperSlide>
              <SwiperSlide >
                  <Image src={SliderImg} className="img-fluid w-100" />
              </SwiperSlide>
                
            </Swiper>

           <div className='position-relative'>
            <div className='navigation-slide-style  d-flex align-items-center justify-content-between side-space'>
                  <div className="swiper-silder-button-prev"><IoIosArrowBack className='arrow-icon' /></div>
                  <div className="swiper-silder-button-next"><IoIosArrowForward className='arrow-icon'/> </div>
              </div>
           </div>
         </>
      );
    };

export default Slider;
