import React from 'react';
import { Navigation} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import ProdImg from "../../../assets/product/prod-1.png";
import ProductCard from '../../../shared/ProductCard';
import "../LatestProducts/latest_product.css";
import { LuMoveRight , LuMoveLeft } from "react-icons/lu";


const ImmunityBoosting = () => {
    const immunityboosting = [
      {
        title: "Hair Growth Combo with Rx M ",
        price: 20,
        oldPrice: 25,
        imageSrc: ProdImg,
        rating: 4.5,
        inStock: true,
        hairClubMember: ``,
      },
      {
        title: "Hair Growth Combo with Rx M ",
        price: 20,
        oldPrice: 25,
        imageSrc: ProdImg,
        rating: 4.5,
        inStock: true,
      },
      {
        title: "Hair Growth Combo with Rx M ",
        price: 20,
        oldPrice: 25,
        imageSrc: ProdImg,
        rating: 4.5,
        inStock: true,
      },
      {
        title: "Hair Growth Combo with Rx M ",
        price: 20,
        oldPrice: 25,
        imageSrc: ProdImg,
        rating: 4.5,
        inStock: true,
      },
      {
        title: "Hair Growth Combo with Rx M ",
        price: 20,
        oldPrice: 25,
        imageSrc: ProdImg,
        rating: 4.5,
        inStock: true,
      },
      {
        title: "Hair Growth Combo with Rx M ",
        price: 20,
        oldPrice: 25,
        imageSrc: ProdImg,
        rating: 4.5,
        inStock: false,
        hairClubMember: ``,
      },
      {
        title: "Hair Growth Combo with Rx M ",
        price: 20,
        oldPrice: 25,
        imageSrc: ProdImg,
        rating: 4.5,
        inStock: true,
      },
      {
        title: "Hair Growth Combo with Rx M ",
        price: 20,
        oldPrice: 25,
        imageSrc: ProdImg,
        rating: 4.5,
        inStock: true,
      },
      {
        title: "Hair Growth Combo with Rx M ",
        price: 20,
        oldPrice: 25,
        imageSrc: ProdImg,
        rating: 4.5,
        inStock: false,
      },
      {
        title: "Hair Growth Combo with Rx M ",
        price: 20,
        oldPrice: 25,
        imageSrc: ProdImg,
        rating: 4.5,
        inStock: true,
      },
      {
        title: "Hair Growth Combo with Rx M ",
        price: 20,
        oldPrice: 25,
        imageSrc: ProdImg,
        rating: 4.5,
        inStock: true,
      },
      {
        title: "Hair Growth Combo with Rx M ",
        price: 20,
        oldPrice: 25,
        imageSrc: ProdImg,
        rating: 4.5,
        inStock: false,
      },
      ];

  return (
    <>
     <section className='pt-5 pbottom-5'>
         <div className='side-space'>
            <Row>
                <Col lg="6" md="12">
                    <h3 className='common-heading mb-3'> Immunity Boosting  </h3>
                </Col>

                <Col lg="6" md="12">
                    <div className='d-flex justify-content-between justify-content-sm-end align-items-center mb-3'>
                      <Link to="/shop" className="view-all me-5"> View All </Link>
                        <div className='navigation-style position-relative d-flex align-items-center'>
                            <div className="swiper-custom-button-prev"><LuMoveLeft className='arrow-icon' /></div>
                            <div className="swiper-custom-button-next"><LuMoveRight className='arrow-icon'/> </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                {/* product List */}
                <Col xs lg="12" md="12">
                    <Swiper
                        modules={[Navigation]}
                        spaceBetween={50}
                        slidesPerView={3}
                        navigation={{
                            prevEl: '.swiper-custom-button-prev',
                            nextEl: '.swiper-custom-button-next',
                        }}
                        pagination={{ clickable: false }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                        breakpoints={{
                          0: {
                            slidesPerView:1.5,
                            spaceBetween: 10
                           },
                          400: {
                            slidesPerView:2,
                            spaceBetween: 10
                           },

                          600: {
                            slidesPerView:2.7,
                            spaceBetween: 10
                           },

                          700: {
                            slidesPerView:3.3,
                            spaceBetween: 10
                           },

                          800: {
                            slidesPerView:4,
                            spaceBetween: 10
                           },
                            1000: {
                                slidesPerView:4.3,
                                spaceBetween: 10
                            },
                            1200: {
                                slidesPerView: 5.3,
                                spaceBetween: 20
                            },

                            1366: {
                                slidesPerView: 6.2,
                                spaceBetween: 20
                            },

                            1550: {
                                slidesPerView: 7.3,
                                spaceBetween: 20
                            }
                        }} >
                        {immunityboosting.map((product, index) => (
                            <SwiperSlide key={index}>
                                <ProductCard product={product} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    
                   
                </Col>
            </Row>
        </div>
    </section>
    </>
  )
}

export default ImmunityBoosting;
