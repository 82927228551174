import React from "react";
import CustomButton from "../../shared/CustomButton";
import {Form } from "react-bootstrap";

const ForgotPassword = () =>{
 
  return (
    <div className="fullscreen ptop-5 pbottom-5">
        <div className="side-space">
          <div className="login-container form-lable">
            <h2 className="text-center">Resset Your Password? </h2>
            <p className="mb-4 text-center">we'll send you a message to help you
      reset your password.</p>

            <Form>
              <Form.Group className="mb-3" controlId="Email">
                <Form.Control
                  className=""
                  type="text"
                  placeholder="Type Your Email "
                  required
                />
              </Form.Group>

              {/* Login Button  */}
              <div className="pt-3">
                <CustomButton
                  border={false}
                  borderWidth={0}
                  rounded={true}
                  color="#ffd814"
                  text="Continue"
                  textColor="#fff"
                  hoverTextColor="#000"
                  backgroundColor="#C75E0A"
                  hoverBackgroundColor="#CFD8DC"
                />
              </div>
            </Form>
          </div>
        </div>
    </div>
  );
}

export default ForgotPassword;
